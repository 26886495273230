<template>
    <v-container fluid>
        <v-row class="text-center">
          <v-col>
            <h1 class="display-2 font-weight-bold mb-3">403: Forbidden</h1>
          </v-col>
          <v-col cols="12">
            <v-img
            :src="require('../../assets/forbidden.gif')"
            class="my-3"
            contain
            height="200"
            background = "black"
            />
          </v-col>
          <v-col>
            <p class="subheading font-weight-regular">
                {{ text }}
               <br>
            </p>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
  data() {
    return {
      text: 'You do not have access to this resource',
    };
  },
};
</script>
