<template>
    <v-card
      :color="colorCard"
      class="mx-auto"
      max-width="344"
      outlined
    >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="text-overline mb-4">
                {{title}}
              </div>
            <v-list-item-title class="text-h5 mb-1">
            {{type}}
            </v-list-item-title>
            <v-list-item-subtitle>{{content}}</v-list-item-subtitle>
            <v-list-item-subtitle>{{destinyShop}}</v-list-item-subtitle>
            <v-list-item-subtitle>{{date}}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-avatar tile size="80" :color="colorCard">
               <template v-if="type === 'Info'">
                  <v-icon
                    color="white"
                    size="80"
                    class="mr-2"
                    > info
                  </v-icon>
              </template>
              <template v-if="type === 'Warning'">
                  <v-icon
                    color="white"
                    size="80"
                    class="mr-2"
                    > warning
                  </v-icon>
              </template>
              <template v-if="type === 'Error'">
                <v-icon
                  color="white"
                  size="80"
                  class="mr-2"
                  > bug_report
                </v-icon>
              </template>
          </v-list-item-avatar>
        </v-list-item>
       </v-card>
</template>

<script>
export default {
  props: {
    colorCard: { type: String, default: 'primary' },
    title: { type: String, default: 'Title' },
    content: { type: String, default: 'Content' },
    destinyShop: { type: String, default: 'Shop' },
    date: { type: String, default: 'YYYY-MM-DD' },
    type: { type: String, default: 'Info' },
  },
};
</script>
