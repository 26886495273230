<template>
   <v-flex>
     <notify-custom
   :showNotify="show"
   :type="tipoNotify"
   :message="msgNotify"
   :icontext="iconNotify"
   :color="color">
   </notify-custom>
    <v-data-table
      :page="page"
      :pageCount="numberOfPages"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :headers="headers"
      :items="objetos"
      :footer-props="propsTextFooterDataTable"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Dropshippers Variants</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            class="text-xs-center"
            v-model="search"
            append-icon="search"
            :label="txtMessages.txtSearch"
            single-line
            hide-details
            @change="listar()"
            >
          </v-text-field>
          <v-spacer></v-spacer>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-btn
           color="primary"
                class="ma-2 white--text"
                fab
                small
          :href="urlBack+'api/variant/dropshipper/get-file'">
            <v-icon dark>
                file_download
              </v-icon>
          </v-btn>
        </v-toolbar>
        <v-toolbar flat>
          <v-toolbar-title>
            <small>
            </small>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item.created_at="{ item }">
        <td>
          {{$moment(item.created_at).format('YYYY-MM-DD HH:MM')}}
        </td>
      </template>
      <template v-slot:item.updated_at="{ item }">
        <td>
          {{$moment(item.updated_at).format('YYYY-MM-DD HH:MM')}}
        </td>
      </template>
      <template v-slot:item.active="{ item }">
        <td>
          <div v-if="item.active">
              <v-chip color="green" dark> {{txtMessages.txtActive}} </v-chip>
          </div>
          <div v-else>
            <v-chip color="red" dark> {{txtMessages.txtInactive}} </v-chip>
          </div>
        </td>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="listar()"
        >
          {{txtMessages.txtReset}}
        </v-btn>
      </template>
    </v-data-table>
    </v-flex>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import notifyCustom from './partials/notifyDialog.vue';
import langMsg from '../config/generalMessages';

export default {
  components: {
    'notify-custom': notifyCustom,
  },
  data() {
    return {
      urlBack: process.env.VUE_APP_API_URL,
      txtMessages: langMsg,
      propsTextFooterDataTable: {
        itemsPerPageText: 'Rows per page',
        itemsPerPageAllText: 'All',
      },
      dialog: false,
      search: '',
      objetos: [],
      objExcel: [],
      dialogDelete: false,
      page: 1,
      numberOfPages: 10,
      options: {},
      totalItems: 0,
      headers: [
        {
          text: langMsg.txtCreationDate, align: 'start', value: 'created_at', sortable: false,
        },
        {
          text: langMsg.txtUpdateDate, align: 'start', value: 'updated_at', sortable: false,
        },
        { text: 'dropshipper', value: 'dropshipper_id.name', sortable: false },
        { text: 'sku', value: 'sku', sortable: false },
        { text: 'inventory', value: 'inventory_dropshipper', sortable: false },
      ],
      columns: [
        { label: langMsg.txtCreationDate, field: 'created_at' },
        { label: langMsg.txtUpdateDate, field: 'updated_at' },
        { label: 'dropshipper', field: 'dropshipper_id.name' },
        { label: 'sku', field: 'sku' },
        { label: 'inventory', field: 'inventory_dropshipper' },
      ],
      // eslint-disable-next-line
      data: {},
      // --- events data ---
      loading: false,
      tipoNotify: 'Info',
      msgNotify: '--',
      show: false,
      color: 'primary',
      iconNotify: 'info',
    };
  },
  computed: {
  },
  watch: {
    dialog(val) {
      const exp = val || this.close();
      return exp;
    },
    dialogDelete(val) {
      const exp = val || this.closeDelete();
      return exp;
    },
    options: {
      handler() {
        this.listar();
      },
    },
    deep: true,
  },
  created() {
    axios.defaults.headers.common.authorization = this.$store.state.token;
    this.$store.commit('SET_LAYOUT', 'principal-layout');
    this.listar();
  },

  methods: {
    notifyMessage(type, message) {
      this.tipoNotify = type;
      this.msgNotify = message;
      this.show = true;
      if (type === 'Info') {
        this.color = 'primary';
        this.iconNotify = 'info';
      } else if (type === 'Success') {
        this.color = 'green';
        this.icontext = 'check_circle';
      } else if (type === 'Error') {
        this.color = 'red';
        this.icontext = 'error';
      }
    },
    getTypeLog(type) {
      if (!type === null || type === 'none') return false;
      let result = null;
      switch (type) {
        case 'Info':
          result = 0;
          break;
        case 'Warning':
          result = 1;
          break;
        case 'Error':
          result = 2;
          break;
        default:
          result = false;
      }
      return result;
    },
    getTypeLogReverse(type) {
      let result = null;
      switch (type) {
        case 0:
          result = 'Info';
          break;
        case 1:
          result = 'Warning';
          break;
        case 2:
          result = 'Error';
          break;
        default:
          result = '-';
      }
      return result;
    },
    listar() {
      const me = this;
      me.loading = true;
      const { page, itemsPerPage } = me.options;
      const items = itemsPerPage || 10;
      const pageNumber = page || 1;
      this.idDropshipper = this.$route.params.id;
      me.objetos = [];
      let urlConsult = `/variant/dropshipper/getmany?limit=${items}&page=${pageNumber}`;
      const type = me.getTypeLog(this.typeLogs);
      const title = me.titleLogs || false;
      const search = me.search !== '' ? me.search : false;
      if (type || type === 0) urlConsult = `${urlConsult}&type=${type}`;
      if (title) urlConsult = `${urlConsult}&title=${title}`;
      if (search) urlConsult = `${urlConsult}&search=${search}`;
      axios.get(urlConsult).then((res) => {
        console.log('res: ', res);
        me.loading = false;
        me.objetos = res.data.body.docs;
        me.objExcel = res.data.body.docs.map((a) => {
          const doc = { ...a };
          doc.dropshipper = doc.dropshipper_id.name;
          doc.created_at = moment(a.created_at).format('YYYY-MM-DD HH:MM');
          return doc;
        });
        me.totalItems = res.data.body.totalDocs;
        me.numberOfPages = res.data.body.pages;
      }).catch((err) => console.log('my err:', err));
    },
    exportar() {
      const me = this;
      me.loading = true;
      const { page, itemsPerPage } = me.options;
      const items = itemsPerPage || 10;
      const pageNumber = page || 1;
      this.idDropshipper = this.$route.params.id;
      let urlConsult = `localhost:3000/variant/dropshipper/get-file?limit=${items}&page=${pageNumber}`;
      const type = me.getTypeLog(this.typeLogs);
      const title = me.titleLogs || false;
      const search = me.search !== '' ? me.search : false;
      if (type || type === 0) urlConsult = `${urlConsult}&type=${type}`;
      if (title) urlConsult = `${urlConsult}&title=${title}`;
      if (search) urlConsult = `${urlConsult}&search=${search}`;
      axios.get(urlConsult).then((res) => {
        console.log(res);
      }).catch((err) => console.log('my err:', err));
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    clean() {
      const empty = '';
      this.nameDropshipper = empty;
    },
  },
};
</script>
