import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import axios from 'axios';
import VueMoment from 'vue-moment';
import * as VueSweetalert2 from 'vue-sweetalert2';
import JsonExcel from 'vue-json-excel';
import VueExcelXlsx from 'vue-excel-xlsx';
// eslint-disable-next-line import/extensions
import Config from './config/index';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import '@mdi/font/css/materialdesignicons.css';

Vue.use(VueExcelXlsx);
Vue.component('downloadExcel', JsonExcel);
Vue.use(VueMoment);
Vue.use(VueClipboard);
Vue.use(VueSweetalert2);
Vue.config.productionTip = false;
axios.defaults.baseURL = Config.config.apiBaseUrl;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
