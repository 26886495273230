import Vue from 'vue';
import Vuex from 'vuex';
import decode from 'jwt-decode';
// eslint-disable-next-line import/no-cycle
import router from '../router/index';

Vue.use(Vuex);

export default new Vuex.Store({
  // state no puede ser modificado directamente, solo lectura
  state: {
    token: null,
    user: null,
    layout: 'login-layout',
  },
  // logica para cambiar el state en mutations
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
    SET_LAYOUT(state, newLayout) {
      state.layout = newLayout;
    },
  },
  // actions realizan mutaciones
  actions: {
    saveToken({ commit }, data) {
      commit('setToken', data.token);
      commit('setUser', decode(data.token));
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(this.state.user));
    },
    autoLogin({ commit }) {
      const token = localStorage.getItem('token');
      if (token) {
        commit('setToken', token);
        commit('setUser', decode(token));
      }
      return token;
    },
    logout({ commit }) {
      commit('setToken', null);
      commit('setUser', null);
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      commit('SET_LAYOUT', 'login-layout');
      return router.push({ name: 'Login' });
    },
  },
  modules: {
  },
});
