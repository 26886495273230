<template>
  <v-flex>
    <notify-custom :showNotify="show" :type="tipoNotify" :message="msgNotify"
    :icontext="iconNotify" :color="color">
    </notify-custom>
    <v-card class="mx-auto">
      <v-card-title>
        {{ txtMessages.txtConfigTitle }}
      </v-card-title>
      <v-form ref="form">
        <v-container>
          <v-row justify="space-between">
            <v-col cols="12" md="4">
              <v-text-field v-model="shopifyTag" :rules="[rules.required]"
              :label="txtMessages.lblTagProduct"
                @input="noSpace(shopifyTag)" required></v-text-field>
              <v-select v-model="typeSyncInventory" :items="typeSyncList"
              :label="txtMessages.lblTypeSyncInv"></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="emailAudit" :rules="[rules.email, rules.required]"
                :label="txtMessages.lblMailAuditInventory" required></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-switch v-model="statusAuditInventory"
              :label="txtMessages.lblStatusAuditInv" color="primary"
                hide-details></v-switch>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="reset">
            {{ txtMessages.txtCancel }}
          </v-btn>
          <v-btn :disabled="!formIsValid" color="blue darken-1" text @click="update">
            {{ txtMessages.txtSave }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-flex>
</template>
<script>
import axios from 'axios';
import notifyCustom from './partials/notifyDialog.vue';
import langMsg from '../config/generalMessages';

export default {
  components: {
    'notify-custom': notifyCustom,
  },
  data() {
    return {
      txtMessages: langMsg,
      shopifyTag: '',
      emailAudit: '',
      statusAuditInventory: '',
      typeSyncInventory: '',
      typeSyncList: ['shopifyProductQty', 'availableQtys', 'locationsQtys'],
      idDoc: '',

      valida: 0,
      validaMensaje: [],

      tipoNotify: 'Error',
      msgNotify: 'el nuevo msj',
      show: false,
      color: 'primary',
      iconNotify: 'info',

      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
      },

    };
  },
  computed: {
    formIsValid() {
      return (
        this.shopifyTag
        && this.emailAudit
        && this.statusAuditInventory
      );
    },
  },
  mounted() {
    this.$store.commit('SET_LAYOUT', 'principal-layout');
    axios.defaults.headers.common.authorization = this.$store.state.token;
    return this.getOne();
  },
  methods: {
    notifyMessage(type, message) {
      this.tipoNotify = type;
      this.msgNotify = message;
      this.show = true;
      if (type === 'Info') {
        this.color = 'primary';
        this.iconNotify = 'info';
      } else if (type === 'Success') {
        this.color = 'green';
        this.icontext = 'check_circle';
      } else if (type === 'Error') {
        this.color = 'red';
        this.icontext = 'error';
      }
    },
    noSpace() {
      const word = this.shopifyTag.replace(' ', '');
      this.shopifyTag = word;
    },
    showuser() {
      return this.$store.state.user;
    },
    reset() {
      this.$refs.form.reset();
    },
    clean() {
      this.valida = 0;
      this.validaMensaje = [];
      this.errorMsg = '';
      this.showError = 0;
      this.successMsg = '';
      this.showSuccess = 0;

      this.shopifyTag = '';
      this.emailAudit = '';
      this.statusAuditInventory = '';
    },
    getOne() {
      // nuevo
      return axios.get('config/getmany').then((response) => {
        const { docs } = response.data.body;
        this.idDoc = docs[0]._id;
        this.shopifyTag = docs[0].shopify_tag_product;
        this.emailAudit = docs[0].mail_audit_inventory;
        this.statusAuditInventory = docs[0].status_audit_inventory;
        this.typeSyncInventory = docs[0].type_sync_inventory;
      }).catch((err) => {
        console.log(err);
        return 'fail';
      });
    },
    update() {
      // nuevo
      const newdoc = {
        shopify_tag_product: this.shopifyTag,
        mail_audit_inventory: this.emailAudit,
        status_audit_inventory: this.statusAuditInventory,
        type_sync_inventory: this.typeSyncInventory,
      };
      axios.put(`config/update/${this.idDoc}`, newdoc).then((response) => {
        this.notifyMessage('Success', 'updated');
        const docs = response.data.body;
        this.shopifyTag = docs.shopify_tag_product;
        this.emailAudit = docs.mail_audit_inventory;
        this.statusAuditInventory = docs.status_audit_inventory;
        this.typeSyncInventory = docs.type_sync_inventory;
        return response.data;
      }).catch((err) => {
        this.errorMsg = '';
        this.showError = 0;
        this.onError(err);
        this.notifyMessage('Error', err.message);
        return 'fail';
      });
    },
  },
};
</script>
