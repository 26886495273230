<template>
  <v-flex>
    <v-dialog v-model="show" max-width="600px">
      <v-card class="mx-auto" :color="color" dark>
        <v-card-title>
          <v-icon large left>
            {{ iconNotify }}
          </v-icon>
          {{ tipoNotify }}
        </v-card-title>
        <v-card-text>{{ msgNotify }}</v-card-text>
      </v-card>
    </v-dialog>
    <v-data-table :headers="myHeader" :items="objetos"
    :search="search" sort-by="date" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Dropshipper</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field class="text-xs-center" v-model="search"
          append-icon="search" :label="txtMessages.txtSearch"
            single-line hide-details>
          </v-text-field>
          <v-spacer></v-spacer>
          <v-divider class="mx-4" inset vertical></v-divider>
          <vue-excel-xlsx :data="objExcel" :columns="myColumn"
          :file-name="'dropshipperList'" :file-type="'xlsx'"
            :sheet-name="'list'">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" class="ma-2 white--text" fab small v-bind="attrs" v-on="on">
                  <v-icon dark>
                    file_download
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ txtMessages.txtExport }}</span>
            </v-tooltip>
          </vue-excel-xlsx>
          <v-dialog v-model="aiModalInv" max-width="600px">
            <v-card>
              <v-card-title class="headline" v-if="aiActionInv == 1">
                {{ txtMessages.txtActivateSyncInv }} {{ aiNameInv }}
              </v-card-title>
              <v-card-title class="headline" v-if="aiActionInv == 2">
                {{ txtMessages.txtInactivateSyncInv }} {{ aiNameInv }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray darken-1" text @click="closeaiInvModal">
                  {{ txtMessages.txtClose }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="aiModalProd" max-width="600px">
            <v-card>
              <v-card-title class="headline" v-if="aiActionProd == 1">
                {{ txtMessages.txtActivateSyncProd }} {{ aiNameProd }}
              </v-card-title>
              <v-card-title class="headline" v-if="aiActionProd == 2">
                {{ txtMessages.txtInactivateSyncProd }} {{ aiNameProd }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray darken-1" text @click="closeaiProdModal">
                  {{ txtMessages.txtClose }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="aiModalOrder" max-width="600px">
            <v-card>
              <v-card-title class="headline" v-if="aiActionOrder == 1">
                {{ txtMessages.txtActivateSyncOrder }} {{ aiNameOrder }}
              </v-card-title>
              <v-card-title class="headline" v-if="aiActionOrder == 2">
                {{ txtMessages.txtInactivateSyncOrder }} {{ aiNameOrder }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray darken-1" text @click="closeaiOrderModal">
                  {{ txtMessages.txtClose }}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="confirmModal" max-width="600px">
            <v-card>
              <v-card-title class="headline">
                {{ txtMessages.txtConfirm }}
              </v-card-title>
              <v-card-text>{{ msgConfirm }}</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray darken-1" text @click="closeConfirmModal">
                  {{ txtMessages.txtCancel }}
                </v-btn>
                <template v-if="typeSync == 'product'">
                  <v-btn :loading="loading" color="orange darken-1" text @click="syncProduct">
                    {{ txtMessages.txtAccept }}
                  </v-btn>
                </template>
                <template v-if="typeSync == 'inventory'">
                  <v-btn :loading="loading" color="orange darken-1" text @click="syncInventory">
                    {{ txtMessages.txtAccept }}
                  </v-btn>
                </template>
                <template v-if="typeSync == 'order'">
                  <v-btn :loading="loading" color="orange darken-1" text @click="syncOrders">
                    {{ txtMessages.txtAccept }}
                  </v-btn>
                </template>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="editModal" max-width="800px">
            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ txtMessages.txtDetails }}
                  <strong color="primary">
                    {{ nameDropshipper }}
                  </strong>
                </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form" lazy-validation>
                    <v-row justify="space-between">
                      <v-col cols="12" md="6">
                        <v-flex>
                          <v-text-field disabled :label="txtMessages.lblCompanyName"
                          v-model="nameDropshipper"
                            :rules="nameRules">
                          </v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field :label="txtMessages.lblContactName"
                          v-model="nameContact" :rules="nameRules">
                          </v-text-field>
                        </v-flex>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-flex>
                          <v-text-field type="email" :label="txtMessages.lblContactEmail"
                          v-model="emailContact"
                            :rules="emailRules">
                          </v-text-field>
                        </v-flex>
                        <v-flex>
                          <v-text-field type="tel" :label="txtMessages.lblContactPhone"
                          v-model="phoneContact">
                          </v-text-field>
                        </v-flex>
                      </v-col>
                      <v-flex xs12 sm12 md12 v-show="valida">
                        <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
                        </div>
                      </v-flex>
                    </v-row>
                    <v-row justify="space-between">
                      <v-col cols="12" md="6">
                        <p>Selecciona un warehouse si necesitas que los productos se sincronicen
                          en un warehouse diferente al por default
                        </p>
                        <v-flex>
                          <v-select v-model="locationIdDropshipper"
                          :items="listLocations" :label="'warehouse'"
                            item-text="name" item-value="id"></v-select>
                        </v-flex>
                        <v-flex>
                        </v-flex>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gray darken-1" text @click="close">
                  {{ txtMessages.txtCancel }}
                </v-btn>
                <v-btn color="primary darken-1" text @click="editItem">
                  {{ txtMessages.txtSaveChanges }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

    <v-dialog
      v-model="productModal"
      scrollable
      max-width="300px"
    >
      <v-card>
        <v-card-title>Sincronizacion automatica de productos
          <br/> ({{ nameCustomer }})</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          <p>
            Seleccione las opciones que desea activar para el cliente
          </p>
          <v-radio-group
            v-model="dialogm1"
            column
          >
            <v-checkbox
              v-model="createProduct"
              label="crear productos"
              color="blue"
              value="red"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="deleteProduct"
              label="eliminar productos"
              color="red"
              value="red"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="creationVariants"
              label="crear variantes nuevas"
              color="blue"
              value="red"
              hide-details
            ></v-checkbox>
            <v-checkbox
              v-model="updateVariants"
              label="actualizar variantes existentes"
              color="blue"
              value="red"
              hide-details
            ></v-checkbox>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="close()"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="editItemSyncProduct()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        </v-toolbar>
        <v-toolbar flat>
          <v-toolbar-title>
            <small>
            </small>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-select v-model="statusDrsh" :items="itemsStatusDrsh"
          :label="txtMessages.lblSelectStatus"
            @change="listar()"></v-select>
          <v-spacer></v-spacer>
          <v-select v-if="user.rol !== 'comercial' && user.rol !== 'support'"
          v-model="agent" :items="agents"
            :label="txtMessages.lblSelectAgent" @change="listar()"></v-select>
        </v-toolbar>
      </template>
      <template v-slot:item.agents="{ item }">
        <div v-if="item.agents">
          <v-chip variant="outlined" v-for="agent in item.agents" :key="agent.id">
            {{ agent.name }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.created_at="{ item }">
        <td>
          {{ $moment(item.created_at).format('YYYY-MM-DD HH:MM') }}
        </td>
      </template>
      <template v-slot:item.name="{ item }">
        <td>
          <v-chip v-if="item.deleted" class="ma-2" color="red" text-color="white">
            <v-avatar left>
              <v-icon>mdi-close</v-icon>
            </v-avatar>
            {{ item.name }}
          </v-chip>
          <v-chip v-else class="ma-2" color="indigo" text-color="white">
            <v-avatar left>
              <v-icon>mdi-checkbox-marked-circle</v-icon>
            </v-avatar>
            {{ item.name }}
          </v-chip>
          <div v-if="item.errorInventory == 'Response code 404 (Not Found)'">
          <p style="color:red">verify inventory warehause location</p>
        </div>
        <div v-else-if="item.errorInventory == 'Response code 503 (Service Unavailable)'">
          <p style="color:red">Shopify Service Unavailable</p>
        </div>
        </td>
      </template>
      <template v-slot:item.syncProductCount="{ item }">
        <div v-if="item.syncProductCount >= 1">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <title>package-variant-closed-check</title>
            <!-- eslint-disable-next-line -->
            <path d="M12 2C11.8 2 11.6 2.1 11.4 2.2L3.5 6.6C3.2 6.8 3 7.1 3 7.5V16.5C3 16.9 3.2 17.2 3.5 17.4L11.4 21.8C11.6 21.9 11.8 22 12 22S12.4 21.9 12.6 21.8L13.5 21.3C13.2 20.7 13.1 20 13 19.3V12.6L19 9.2V13C19.7 13 20.4 13.1 21 13.3V7.5C21 7.1 20.8 6.8 20.5 6.6L12.6 2.2C12.4 2.1 12.2 2 12 2M12 4.2L18 7.5L16 8.6L10.1 5.2L12 4.2M8.1 6.3L14 9.8L12 10.9L6 7.5L8.1 6.3M5 9.2L11 12.6V19.3L5 15.9V9.2M21.3 15.8L17.7 19.4L16.1 17.8L15 19L17.8 22L22.6 17.2L21.3 15.8Z" />
          </svg>
        </div>
        <div v-else>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <title>package-variant-closed-remove</title>
            <!-- eslint-disable-next-line -->
            <path d="M13 12.6L19 9.2V13C19.7 13 20.4 13.1 21 13.4V7.5C21 7.1 20.8 6.8 20.5 6.6L12.6 2.2C12.4 2.1 12.2 2 12 2S11.6 2.1 11.4 2.2L3.5 6.6C3.2 6.8 3 7.1 3 7.5V16.5C3 16.9 3.2 17.2 3.5 17.4L11.4 21.8C11.6 21.9 11.8 22 12 22S12.4 21.9 12.6 21.8L13.5 21.3C13.2 20.7 13.1 20 13 19.3M12 4.2L18 7.5L16 8.6L10.1 5.2L12 4.2M11 19.3L5 15.9V9.2L11 12.6V19.3M12 10.8L6 7.5L8 6.3L14 9.8L12 10.8M16.9 15.5L19 17.6L21.1 15.5L22.5 16.9L20.4 19L22.5 21.1L21.1 22.5L19 20.4L16.9 22.5L15.5 21.1L17.6 19L15.5 16.9L16.9 15.5Z" />
          </svg>
        </div>
      </template>
      <template v-slot:item.needPayShopify="{ item }">
        <div v-if="item.needPayShopify">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <title>store-alert</title>
            <!-- eslint-disable-next-line -->
            <path d="M2 7L1 12V14H2V20H12V14H16V20H18V14H19V12L18 7H2M10 18H4V14H10V18M18 6H2V4H18V6M23 7V13H21V7H23M21 15H23V17H21V15Z" />
          </svg>
        </div>
        <div v-else>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <title>store</title>
            <!-- eslint-disable-next-line -->
            <path d="M12,18H6V14H12M21,14V12L20,7H4L3,12V14H4V20H14V14H18V20H20V14M20,4H4V6H20V4Z" />
          </svg>
        </div>
      </template>
      <template v-slot:item.inventoryEnabled="{ item }">
        <td>
          <v-switch v-model="item.inventoryEnabled" color="orange" hide-details
            @click="activeInactiveInvShow(item.inventoryEnabled ? 1 : 2, item)"></v-switch>
        </td>
      </template>
      <template v-slot:item.enabled="{ item }">
        <td>
          <v-switch v-model="item.enabled" color="red" hide-details
            @click="activeInactiveOrderShow(item.enabled ? 1 : 2, item)"></v-switch>
        </td>
      </template>
      <template v-slot:item.productEnabled="{ item }">
        <td>
          <v-switch v-model="item.productEnabled" color="blue" hide-details
            @click="activeInactiveProdShow(item.productEnabled ? 1 : 2, item)"></v-switch>
        </td>
      </template>
      <template v-slot:item.priceEnabled="{ item }">
        <td>
          <v-switch v-model="item.priceEnabled" color="green" hide-details
            @click="activeInactivePriceShow(item.priceEnabled ? 1 : 2, item)"></v-switch>
        </td>
      </template>
      <!-- <template v-slot:item.auto_payment="{ item }">
        <td>
          <v-switch v-model="item.auto_payment" color="primary" hide-details
            @click="activeInactivePaymentShow(item.priceEnabled ? 1 : 2, item)"></v-switch>
        </td>
      </template> -->
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-2" color="blue" v-bind="attrs" v-on="on"
              @click="showConfirmModal(txtMessages.txtQtSyncProducts, 'product', item)">
              <v-icon> mdi-sync </v-icon>

            </v-btn>
          </template>
          <span>{{ txtMessages.txtSyncProducts }}</span>
        </v-tooltip>
        <template v-if="user.rol === 'dev'">
          <v-tooltip v-if="item.inventoryEnabled" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="#ff9800" class="mr-2" v-bind="attrs" v-on="on"
                @click="showConfirmModal(txtMessages.txtQtSyncInventory, 'inventory', item)">
                <v-icon> mdi-sync
                </v-icon>
              </v-btn>
            </template>
            <span>{{ txtMessages.txtSyncInventory }}</span>
          </v-tooltip>
          <v-tooltip v-else bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon disabled color="#F44336" class="mr-2" v-bind="attrs" v-on="on">
                <v-icon> sync_disabled
                </v-icon>
              </v-btn>
            </template>
            <span>{{ txtMessages.txtSyncInvDisabled }}</span>
          </v-tooltip>
        </template>
        <template v-if="user.rol === 'dev'">
          <v-tooltip v-if="item.enabled" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="#F44336" class="mr-2" v-bind="attrs" v-on="on"
                @click="showConfirmModal(txtMessages.txtQtSyncOrders, 'order', item)">
                <v-icon> mdi-sync
                </v-icon>
              </v-btn>
            </template>
            <span>{{ txtMessages.txtSyncOrders }} </span>
          </v-tooltip>
          <v-tooltip v-else bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon disabled color="#F44336" class="mr-2" v-bind="attrs" v-on="on">
                <v-icon> sync_disabled
                </v-icon>
              </v-btn>
            </template>
            <span>{{ txtMessages.txtSyncOrderDisabled }}</span>
          </v-tooltip>
        </template>
      </template>
      <template v-slot:item.records="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="black" class="mr-2" v-bind="attrs"
            v-on="on" @click="goToLogList(item)">
              <v-icon> highlight
              </v-icon>
            </v-btn>
          </template>
          <span>{{ txtMessages.txtGoToLogList }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="#F44336" class="mr-2" v-bind="attrs"
            v-on="on" @click="goToOrderList(item)">
              <v-icon> receipt
              </v-icon>
            </v-btn>
          </template>
          <span>{{ txtMessages.txtGoToOrdersList }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="#032a41" class="mr-2" v-bind="attrs"
            v-on="on" @click="goToOrderListNoSynced(item)">
              <v-icon> receipt
              </v-icon>
            </v-btn>
          </template>
          <span>{{ txtMessages.txtGoToOrdersListNoSync }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="blue" class="mr-2" v-bind="attrs"
            v-on="on" @click="goToListProducts(item)">
              <v-icon> receipt
              </v-icon>
            </v-btn>
          </template>
          <span>{{ txtMessages.txtGoToOrdersListProducts }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.options="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" class="mr-2" v-bind="attrs"
            v-on="on" @click="showProductModal(item)">
              <v-icon> mdi-format-list-text
              </v-icon>
            </v-btn>
          </template>
          <span>{{ txtMessages.txtProduct }}</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" class="mr-2" v-bind="attrs"
            v-on="on" @click="showEditModal(item)">
              <v-icon> edit
              </v-icon>
            </v-btn>
          </template>
          <span>{{ txtMessages.txtDetails }}</span>
        </v-tooltip>
        <v-tooltip v-if="item.deleted == false" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="primary" class="mr-2" v-bind="attrs"
            v-on="on" @click="suspendCustomer(item._id)">
              <v-icon> mdi-close-network
              </v-icon>
            </v-btn>
          </template>
          <span>{{ txtMessages.suspendService }}</span>
        </v-tooltip>
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="#F44336" class="mr-2" v-bind="attrs" v-on="on"
              :href="urlBack + 'api/variant/dropshipper/get-file' + '?dropshipper=' + item._id">
              <v-icon> file_download
              </v-icon>
            </v-btn>
          </template>
          <span>{{ txtMessages.downloadProducts }}</span>
        </v-tooltip> -->
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="listar()">
          {{ txtMessages.txtReset }}
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="alreadyUpdate" max-width="600px">
            <v-card>
              <v-card-title class="headline">
              MESSAGE
              </v-card-title>
              <v-card-text>{{ messageUpdate }}</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
  </v-flex>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import langMsg from '../config/generalMessages';

export default {
  data() {
    return {
      alreadyUpdate: false,
      messageUpdate: '',
      dialogm1: '',
      dialog1: false,
      user: null,
      urlBack: process.env.VUE_APP_API_URL,
      objDropshipper: {},
      locationIdDropshipper: null,
      listLocations: [],
      txtMessages: langMsg,
      itemsStatusDrsh: langMsg.itemsStatusDrsh,
      itemsTitle: langMsg.itemsTitleLogs,
      statusDrsh: null,
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      modal: false,
      numberCreditCard: '',
      nameCreditCard: '',
      expirationDate: '',
      cvv: '',
      zipCode: '',
      agents: [],
      agent: null,
      dialog: false,
      search: '',
      objetos: [],
      objExcel: [],
      dialogDelete: false,
      headers: [
        {
          text: langMsg.txtCreationDate, align: 'start', value: 'created_at', sortable: false, width: '10%',
        },
        {
          text: langMsg.txtSyncProduct, value: 'syncProductCount', sortable: false, width: '3%',
        },
        {
          text: langMsg.txtNeedPayShopify, value: 'needPayShopify', sortable: false, width: '3%',
        },
        { text: 'Dropshipper', value: 'name', sortable: true },
        { text: langMsg.txtContact, value: 'contact.name', sortable: false },
        { text: langMsg.txtShop, value: 'config.shop', sortable: false },
        { text: langMsg.txtSyncInventory, value: 'inventoryEnabled', width: '3%' },
        { text: langMsg.txtSyncOrders, value: 'enabled', width: '3%' },
        {
          text: langMsg.txtCondPrice, value: 'priceEnabled', sortable: false, width: '3%',
        },
        // { text: langMsg.txtAutoPayment, value: 'auto_payment', sortable: false },
        {
          text: langMsg.txtToSync, value: 'actions', sortable: false, width: '20%',
        },
        {
          text: langMsg.txtRecords, value: 'records', sortable: false, width: '20%',
        },
        {
          text: langMsg.txtOptions, value: 'options', sortable: false, width: '20%',
        },
      ],
      columns: [
        { label: langMsg.txtCreationDate, field: 'created_at' },
        { label: langMsg.txtSyncProducts, field: 'syncProductCount' },
        { label: langMsg.txtNeedPayShopify, field: 'needPayShopify' },
        { label: 'dropshipper', field: 'name' },
        { label: langMsg.txtContact, field: 'contact.name' },
        { label: langMsg.txtShop, field: 'config.shop' },
        { label: langMsg.txtSyncInventory, field: 'inventoryEnabled' },
        { label: langMsg.txtSyncOrders, field: 'enabled' },
      ],
      valid: false,
      nameRules: [
        v => !!v || 'Name is required',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          // eslint-disable-next-line
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
        min: v => v.length <= 4 || 'Max 4 characters',
        validCreditCard: creditCradNum => {
          // eslint-disable-next-line
          const regEx =
            /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/;
          const result = regEx.test(creditCradNum) ? true : 'Please enter a valid credit card number';
          return result;
        },
        validDate: value => {
          const today = moment().format('YYYY-MM');
          const date = moment(value).format('YYYY-MM');
          const result = moment(date).isBefore(today) ? 'invalid date' : true;
          return result;
        },
      },
      editedIndex: -1,
      // eslint-disable-next-line
      _id: '',
      emailContact: '',
      phoneContact: '',
      nameContact: '',
      nameDropshipper: '',
      // --- events data ---
      tipoNotify: 'Info',
      msgNotify: '--',
      show: false,
      color: 'primary',
      iconNotify: 'info',

      valida: 0,
      validaMensaje: [],

      aiModalOrder: 0,
      aiActionOrder: 0,
      aiNameOrder: '',
      aiIdOrder: '',

      aiModalInv: 0,
      aiActionInv: 0,
      aiNameInv: '',
      aiIdInv: '',

      aiModalProd: 0,
      aiActionProd: 0,
      aiNameProd: '',
      aiIdProd: '',

      aiModalPrice: 0,
      aiActionPrice: 0,
      aiNamePrice: '',
      aiIdPrice: '',

      aiModalPayment: 0,
      aiActionPayment: 0,
      aiNamePayment: '',
      aiIdPayment: '',

      confirmModal: 0,
      msgConfirm: '',
      confirmId: '',
      typeSync: '',
      loading: false,
      editModal: 0,
      productModal: 0,
      idDropshipper: '',

      createProduct: false,
      deleteProduct: false,
      updateVariants: false,
      creationVariants: false,
    };
  },
  computed: {
    myHeader() {
      if (this.user) {
        let headerOptions = [
          {
            text: langMsg.txtCreationDate, align: 'start', value: 'created_at', sortable: false, width: '10%',
          },
          { text: langMsg.txtSyncProduct, value: 'syncProductCount', sortable: false },
          { text: langMsg.txtNeedPayShopify, value: 'needPayShopify', sortable: false },
          {
            text: 'Dropshipper', value: 'name', sortable: true,
          },
          { text: langMsg.txtContact, value: 'contact.name', sortable: false },
          { text: langMsg.txtContact, value: 'contact.email', sortable: false },
          { text: langMsg.txtShop, value: 'config.shop', sortable: false },
          {
            text: langMsg.txtSyncInventory, value: 'inventoryEnabled', sortable: true, width: '3%',
          },
          {
            text: langMsg.txtSyncOrders, value: 'enabled', sortable: true, width: '3%',
          },
          {
            text: langMsg.txtSyncProducts, value: 'productEnabled', sortable: true, width: '3%',
          },
          {
            text: langMsg.txtToSync, value: 'actions', sortable: false, width: '20%',
          },
          {
            text: langMsg.txtRecords, value: 'records', sortable: false, width: '20%',
          },
          {
            text: langMsg.txtOptions, value: 'options', sortable: false, width: '20%',
          },
        ];
        if (this.user.rol === 'dev' || this.user.rol === 'admin') {
          headerOptions = [
            {
              text: langMsg.txtCreationDate, align: 'start', value: 'created_at', sortable: false, width: '10%',
            },
            {
              text: langMsg.txtSyncProduct, value: 'syncProductCount', sortable: false, width: '3%',
            },
            {
              text: langMsg.txtNeedPayShopify, value: 'needPayShopify', sortable: false, width: '3%',
            },
            {
              text: 'Dropshipper', value: 'name', sortable: true,
            },
            { text: langMsg.txtContact, value: 'contact.name', sortable: false },
            { text: langMsg.txtContact, value: 'contact.email', sortable: false },
            { text: langMsg.txtShop, value: 'config.shop', sortable: false },
            {
              text: langMsg.txtSyncInventory, value: 'inventoryEnabled', sortable: true, width: '3%',
            },
            {
              text: langMsg.txtSyncOrders, value: 'enabled', sortable: true, width: '3%',
            },
            {
              text: langMsg.txtSyncProducts, value: 'productEnabled', sortable: true, width: '3%',
            },
            {
              text: langMsg.txtCondPrice, value: 'priceEnabled', sortable: false, width: '3%',
            },
            {
              text: langMsg.txtToSync, value: 'actions', sortable: false, width: '20%',
            },
            {
              text: langMsg.txtRecords, value: 'records', sortable: false, width: '20%',
            },
            {
              text: langMsg.txtOptions, value: 'options', sortable: false, width: '20%',
            },
            { text: langMsg.txtAgent, value: 'agents', sortable: true },
          ];
        }
        return headerOptions;
      }
      return [];
    },
    myColumn() {
      if (this.user) {
        if (this.user.rol === 'dev' || this.user.rol === 'admin') {
          return [
            { label: langMsg.txtCreationDate, field: 'created_at' },
            { label: 'dropshipper', field: 'name' },
            { label: langMsg.txtContact, field: 'contact.name' },
            { label: langMsg.txtContact, field: 'contact.email' },
            { label: langMsg.txtAgent, field: 'agents' },
            { label: langMsg.txtShop, field: 'config.shop' },
            { label: langMsg.txtSyncInventory, field: 'inventoryEnabled' },
            { label: langMsg.txtSyncOrders, field: 'enabled' },
            { label: langMsg.txtSyncProducts, field: 'productEnabled' },
          ];
        }
        return [
          { label: langMsg.txtCreationDate, field: 'created_at' },
          { label: 'dropshipper', field: 'name' },
          { label: langMsg.txtContact, field: 'contact.name' },
          { label: langMsg.txtContact, field: 'contact.email' },
          { label: langMsg.txtShop, field: 'config.shop' },
          { label: langMsg.txtSyncInventory, field: 'inventoryEnabled' },
          { label: langMsg.txtSyncOrders, field: 'enabled' },
          { label: langMsg.txtSyncProducts, field: 'productEnabled' },
        ];
      }
      return [];
    },
  },
  watch: {
    dialog(val) {
      const exp = val || this.close();
      return exp;
    },
    dialogDelete(val) {
      const exp = val || this.closeDelete();
      return exp;
    },
  },
  created() {
    this.$store.commit('SET_LAYOUT', 'principal-layout');
    const userdata = JSON.parse(localStorage.getItem('user'));
    if (userdata) {
      axios.defaults.headers.common.authorization = this.$store.state.token;
      this.user = this.$store.state.user;
      this.listar();
      this.getAgents();
    } else {
      this.$store.dispatch('logout');
    }
  },

  methods: {
    notifyMessage(type, message) {
      this.tipoNotify = type;
      this.msgNotify = message;
      this.show = true;
      if (type === 'Info') {
        this.color = 'primary';
        this.iconNotify = 'info';
      } else if (type === 'Success') {
        this.color = 'green';
        this.icontext = 'check_circle';
      } else if (type === 'Error') {
        this.color = 'red';
        this.icontext = 'error';
      }
    },
    listar() {
      const me = this;
      me.objetos = [];
      let urlConsult = 'dropshipper/getmany?';
      if (me.statusDrsh) urlConsult += `status=${me.statusDrsh}`;
      if (me.agent !== null) urlConsult += `&agent=${me.agent}`;
      axios.get(urlConsult).then((res) => {
        me.objetos = res.data.body.docs;
        me.objExcel = res.data.body.docs.map((a) => {
          const doc = { ...a };
          doc.agents = doc?.agents.map((b) => b.name).toString();
          doc.created_at = moment(a.created_at).format('YYYY-MM-DD HH:MM');
          return doc;
        });
      }).catch((err) => console.log('my err:', err));
    },
    validate() {
      let result = false;
      const checkForm = this.$refs.form.validate();
      if (!checkForm) {
        if (this.nameDropshipper !== ''
          && this.emailContact !== ''
          && this.nameContact !== ''
          && this.phoneContact !== '') {
          // eslint-disable-next-line
          result = true;
        }
        if (this.numberCreditCard === ''
          && this.expirationDate === ''
          && this.cvv === ''
          && this.zipCode === '' && this.nameCreditCard === '') {
          result = true;
        } else {
          result = false;
        }
      } else {
        result = checkForm;
      }
      return result;
    },
    reset() {
      this.$refs.form.reset();
    },
    clean() {
      const empty = '';
      this.emailContact = empty;
      this.phoneContact = empty;
      this.nameContact = empty;
      this.nameDropshipper = empty;
      this.numberCreditCard = empty;
      this.nameCreditCard = empty;
      this.cvv = empty;
      this.zipCode = empty;
      this.expirationDate = empty;
      this.valida = 0;
      this.validaMensaje = [];
      this.listLocations = [];
      this.locationIdDropshipper = null;
    },
    suspendCustomer(id) {
      const me = this;
      // eslint-disable-next-line
      axios.put(`dropshipper/suspend/${id}`).then((response) => {
        me.listar();
        return 'ok';
      }).catch((err) => {
        this.errorMsg = '';
        this.showError = 0;
        me.notifyMessage('Error', err.response.data.message);
        me.listar();
        return 'fail';
      });
    },
    inactiveOrder() {
      const me = this;
      // eslint-disable-next-line
      axios.put(`dropshipper/inactive/sync/orders/${this.aiIdOrder}`).then((response) => {
        me.aiIdOrder = '';
        me.listar();
        return 'ok';
      }).catch((err) => {
        this.errorMsg = '';
        this.showError = 0;
        me.notifyMessage('Error', err.response.data.message);
        me.listar();
        return 'fail';
      });
    },
    activeOrder() {
      const me = this;
      // eslint-disable-next-line
      axios.put(`dropshipper/active/sync/orders/${this.aiIdOrder}`).then((response) => {
        me.aiIdOrder = '';
        me.listar();
        return 'ok';
      }).catch((err) => {
        this.errorMsg = '';
        this.showError = 0;
        me.notifyMessage('Error', err.response.data.message);
        me.listar();
        console.log(err);
        return 'fail';
      });
    },
    inactiveInv() {
      const me = this;
      // eslint-disable-next-line
      axios.put(`dropshipper/inactive/sync/inventory/${this.aiIdInv}`).then((response) => {
        me.aiIdInv = '';
        me.listar();
        return 'ok';
      }).catch((err) => {
        me.notifyMessage('Error', err.response.data.message);
        me.listar();
        console.log(err);
        return 'fail';
      });
    },
    activeInv() {
      const me = this;
      // eslint-disable-next-line
      axios.put(`dropshipper/active/sync/inventory/${this.aiIdInv}`).then((response) => {
        me.aiIdInv = '';
        me.listar();
        return 'ok';
      }).catch((err) => {
        me.notifyMessage('Error', err.response.data.message);
        me.listar();
        console.log(err);
        return 'fail';
      });
    },
    inactiveProd() {
      const me = this;
      // eslint-disable-next-line
      console.log('aiIdInv', this.aiIdProd);
      axios.put(`dropshipper/inactive/sync/product/${this.aiIdProd}`).then((response) => {
        this.alreadyUpdate = true;
        this.messageUpdate = 'Inactivar sincronizacion de productos';
        console.log(response.data);
        me.aiIdProd = '';
        me.listar();
        return 'ok';
      }).catch((err) => {
        me.notifyMessage('Error', err.response.data.message);
        me.listar();
        console.log(err);
        return 'fail';
      });
    },
    activeProd() {
      const me = this;
      // eslint-disable-next-line
      console.log('aiIdInv', this.aiIdProd);
      axios.put(`dropshipper/active/sync/product/${this.aiIdProd}`).then((response) => {
        this.alreadyUpdate = true;
        this.messageUpdate = 'Esta sincronizacion solo elimina productos inactivos. cualquier otra sincronizacion es por solicitud';
        console.log(response.data);
        me.aiIdProd = '';
        me.listar();
        return 'ok';
      }).catch((err) => {
        me.notifyMessage('Error', err.response.data.message);
        me.listar();
        console.log(err);
        return 'fail';
      });
    },
    inactivePrice() {
      const me = this;
      // eslint-disable-next-line
      axios.put(`dropshipper/inactive/price/${this.aiIdPrice}`).then((response) => {
        me.aiIdPrice = '';
        me.listar();
        return 'ok';
      }).catch((err) => {
        me.notifyMessage('Error', err.response.data.message);
        me.listar();
        console.log(err);
        return 'fail';
      });
    },
    activePrice() {
      const me = this;
      // eslint-disable-next-line
      axios.put(`dropshipper/active/price/${this.aiIdPrice}`).then((response) => {
        me.aiIdPrice = '';
        me.listar();
        return 'ok';
      }).catch((err) => {
        me.notifyMessage('Error', err.response.data.message);
        me.listar();
        console.log(err);
        return 'fail';
      });
    },
    inactivePayment() {
      const me = this;
      // eslint-disable-next-line
      axios.put(`dropshipper/inactive/payment/${this.aiIdPayment}`).then((response) => {
        me.aiIdPayment = '';
        me.listar();
        return 'ok';
      }).catch((err) => {
        me.notifyMessage('Error', err.response.data.message);
        me.listar();
        console.log(err);
        return 'fail';
      });
    },
    activePayment() {
      const me = this;
      // eslint-disable-next-line
      axios.put(`dropshipper/active/payment/${this.aiIdPayment}`).then((response) => {
        me.aiIdPayment = '';
        me.listar();
        return 'ok';
      }).catch((err) => {
        me.notifyMessage('Error', err.response.data.message);
        me.listar();
        console.log(err);
        return 'fail';
      });
    },
    async syncProduct() {
      const me = this;
      // eslint-disable-next-line
      me.loading = true;
      axios.get(`product/sync/${me.confirmId}`).then((response) => {
        me.loading = false;
        me.confirmId = '';
        me.confirmModal = 0;
        me.msgConfirm = '';
        me.notifyMessage('Success', response.data.msg);
        return 'ok';
      }).catch((err) => {
        me.confirmId = '';
        me.confirmModal = 0;
        me.msgConfirm = '';
        me.notifyMessage('Error', err.response.data.message);
        console.log(err);
        return 'fail';
      });
    },
    async syncInventory() {
      const me = this;
      // eslint-disable-next-line
      me.loading = true;
      axios.get(`inventory/sync/shopify-qty/${me.confirmId}`).then((response) => {
        me.loading = false;
        me.confirmId = '';
        me.confirmModal = 0;
        me.msgConfirm = '';
        me.notifyMessage('Success', response.data.msg);
        return 'ok';
      }).catch((err) => {
        me.confirmId = '';
        me.confirmModal = 0;
        me.msgConfirm = '';
        me.notifyMessage('Error', err.response.data.message);
        console.log(err);
        return 'fail';
      });
    },
    async syncOrders() {
      const me = this;
      // eslint-disable-next-line
      me.loading = true;
      axios.get(`order/sync/${me.confirmId}`).then((response) => {
        me.loading = false;
        me.confirmId = '';
        me.confirmModal = 0;
        me.msgConfirm = '';
        me.notifyMessage('Success', response.data.msg);
        return 'ok';
      }).catch((err) => {
        me.confirmId = '';
        me.confirmModal = 0;
        me.msgConfirm = '';
        me.notifyMessage('Error', err.response.data.message);
        console.log(err);
        return 'fail';
      });
    },
    editItem() {
      const result = this.validate();
      if (result) {
        const obj = {
          idLocation: this.locationIdDropshipper,
          name: this.nameDropshipper,
          contact: {
            name: this.nameContact,
            email: this.emailContact,
            phone: this.phoneContact,
          },
        };
        if (this.locationIdDropshipper) {
          obj.config = this.objDropshipper.config;
          obj.config.location_id = this.locationIdDropshipper;
        }
        if (this.numberCreditCard !== '') {
          if (this.numberCreditCard !== ''
            && this.expirationDate !== ''
            && this.cvv !== ''
            && this.zipCode !== '' && this.nameCreditCard) {
            obj.credit_card = {
              number: this.numberCreditCard,
              expiration_date: this.expirationDate,
              cvv: this.cvv,
              zip_code: this.zipCode,
              name: this.nameCreditCard,
            };
          }
        }
        this.loading = true;
        axios.put(`dropshipper/update/${this.idDropshipper}`, obj).then((response) => {
          this.loading = false;
          this.idDropshipper = '';
          this.editModal = 0;
          const { name } = response.data.body;
          this.listar();
          this.notifyMessage('Success', `Success Updated ${name}`);
          this.clean();
          return 'ok';
        }).catch((err) => {
          this.loading = false;
          this.idDropshipper = '';
          this.editModal = 0;
          this.notifyMessage('Error', err.response.data.message);
          return 'fail';
        });
      } else {
        this.loading = false;
        this.idDropshipper = '';
        this.editModal = 0;
        const err = { message: 'forget some fields, or these are wrong' };
        this.notifyMessage('Error', err.response.data.message);
        this.clean();
      }
    },
    editItemSyncProduct() {
      this.loading = true;
      axios.put(`dropshipper/update/${this.idDropshipper}`, {
        syncProduct: {
          creation: this.createProduct,
          deleteInactives: this.deleteProduct,
          updatingVariants: this.updatingVariants,
          creationVariants: this.creationVariants,
        },
      }).then((response) => {
        this.loading = false;
        this.idDropshipper = '';
        this.productModal = 0;
        const { name } = response.data.body;
        this.listar();
        this.notifyMessage('Success', `Success Updated ${name}`);
        this.clean();
        return 'ok';
      }).catch((err) => {
        this.loading = false;
        this.idDropshipper = '';
        this.editModal = 0;
        this.notifyMessage('Error', err.response.data.message);
        return 'fail';
      });
    },
    goToLogList(item) {
      const route = this.$router.resolve({ path: `/app/logs/${item._id}?name=${item.name}`, params: { id: item._id } });
      return window.open(route.href, '_blank');
    },
    goToOrderList(item) {
      const route = this.$router.resolve({ path: `/app/orders/${item._id}`, params: { id: item._id } });
      return window.open(route.href, '_blank');
    },
    goToOrderListNoSynced(item) {
      const route = this.$router.resolve({ path: `/app/orders-no-synced/${item._id}`, params: { id: item._id } });
      return window.open(route.href, '_blank');
    },
    goToListProducts(item) {
      const route = this.$router.resolve({ path: `/app/products/${item._id}/${item.name}`, params: { id: item._id } });
      return window.open(route.href, '_blank');
    },
    showConfirmModal(msg, type, item) {
      this.confirmModal = 1;
      this.msgConfirm = msg;
      this.confirmId = item._id;
      this.typeSync = type;
    },
    showEditModal(item) {
      this.clean();
      this.objDropshipper = item;
      this.editModal = 1;
      this.idDropshipper = item._id;
      this.nameDropshipper = item.name;
      this.nameContact = item.contact.name;
      this.emailContact = item.contact.email;
      this.phoneContact = item.contact.phone;
      this.numberCreditCard = item.credit_card ? item.credit_card.number : '';
      this.nameCreditCard = item.credit_card ? item.credit_card.name : '';
      this.cvv = item.credit_card ? item.credit_card.cvv : '';
      this.zipCode = item.credit_card ? item.credit_card.zip_code : '';
      this.expirationDate = item.credit_card ? item.credit_card.expiration_date : '';
      this.locationIdDropshipper = item.config.location_id;
      this.getLocationsByDropshipper();
    },
    showProductModal(item) {
      this.clean();
      this.productModal = 1;
      this.nameCustomer = item.name;
      this.createProduct = item.syncProduct?.creation;
      this.deleteProduct = item.syncProduct?.deleteInactives;
      this.updateVariants = item.syncProduct?.updatingVariants;
      this.creationVariants = item.syncProduct?.creationVariants;
    },
    getLocationsByDropshipper() {
      const me = this;
      // eslint-disable-next-line
      axios.get(`inventory/get/locations/${this.idDropshipper}`).then((response) => {
        this.listLocations = response.data;
        return 'ok';
      }).catch((err) => {
        this.errorMsg = '';
        this.showError = 0;
        me.notifyMessage('Error', err.response.data.message);
        return 'fail';
      });
    },
    getAgents() {
      const me = this;
      // eslint-disable-next-line
      axios.get(`user/getagent`).then((response) => {
        this.agents = response.data.map((a) => {
          const doc = {
            text: a.name,
            value: a._id,
          };
          return doc;
        });
        this.agents.push({
          text: 'none',
          value: null,
        });
        this.agents.push({
          text: 'Carlos Guillen',
          value: '63ea6b38013d8b030e552c6b',
        });
        return 'ok';
      }).catch((err) => {
        this.errorMsg = '';
        this.showError = 0;
        me.notifyMessage('Error', err.response.data.message);
        return 'fail';
      });
    },
    async activeInactiveOrderShow(action, item) {
      this.aiNameOrder = item.name;
      this.aiIdOrder = item._id;
      // eslint-disable-next-line
      if (action === 2) {
        // desactivar
        const result = await this.inactiveOrder();
        if (result === 'ok') {
          this.aiActionOrder = 2;
          this.aiModalOrder = 1;
        }
      } else if (action === 1) {
        // activar
        const result = await this.activeOrder();
        if (result === 'ok') {
          this.aiActionOrder = 1;
          this.aiModalOrder = 1;
        }
      }
    },
    async activeInactiveInvShow(action, item) {
      this.aiNameInv = item.name;
      this.aiIdInv = item._id;
      // eslint-disable-next-line
      if (action === 2) {
        // desactivar
        const result = await this.inactiveInv();
        if (result === 'ok') {
          this.aiActionInv = 2;
          this.aiModalInv = 1;
        }
      } else if (action === 1) {
        // activar
        const result = await this.activeInv();
        if (result === 'ok') {
          this.aiActionInv = 1;
          this.aiModalInv = 1;
        }
      }
    },
    async activeInactiveProdShow(action, item) {
      // solo activa o inactiva la eliminacion de productos inactivos de la tienda
      this.aiNameProd = item.name;
      this.aiIdProd = item._id;
      // eslint-disable-next-line
      if (action === 2) {
        // desactivar
        const result = await this.inactiveProd();
        if (result === 'ok') {
          this.aiActionProd = 2;
          this.aiModalProd = 1;
        }
      } else if (action === 1) {
        // activar
        const result = await this.activeProd();
        if (result === 'ok') {
          this.aiActionProd = 1;
          this.aiModalProd = 1;
        }
      }
    },
    async activeInactivePriceShow(action, item) {
      this.aiNamePrice = item.name;
      this.aiIdPrice = item._id;
      // eslint-disable-next-line
      if (action === 2) {
        // desactivar
        const result = await this.inactivePrice();
        if (result === 'ok') {
          this.aiActionPrice = 2;
          this.aiModalPrice = 1;
        }
      } else if (action === 1) {
        // activar
        const result = await this.activePrice();
        if (result === 'ok') {
          this.aiActionPrice = 1;
          this.aiModalPrice = 1;
        }
      }
    },
    async activeInactivePaymentShow(action, item) {
      this.aiNamePayment = item.name;
      this.aiIdPayment = item._id;
      // eslint-disable-next-line
      if (action === 2) {
        // desactivar
        const result = await this.inactivePayment();
        if (result === 'ok') {
          this.aiActionPayment = 2;
          this.aiModalPayment = 1;
        }
      } else if (action === 1) {
        // activar
        const result = await this.activePayment();
        if (result === 'ok') {
          this.aiActionPayment = 1;
          this.aiModalPayment = 1;
        }
      }
    },
    closeaiOrderModal() {
      this.aiModalOrder = 0;
      this.aiNameOrder = '';
      this.aiIdOrder = '';
      this.aiActionOrder = 0;
    },
    closeaiInvModal() {
      this.aiModalInv = 0;
      this.aiNameInv = '';
      this.aiIdInv = '';
      this.aiActionInv = 0;
    },

    closeaiProdModal() {
      this.aiModalProd = 0;
      this.aiNameProd = '';
      this.aiIdProd = '';
      this.aiActionProd = 0;
    },

    close() {
      this.editModal = 0;
      this.productModal = 0;
      this.clean();
    },
    closeConfirmModal() {
      this.confirmModal = 0;
      this.msgConfirm = '';
      this.confirmId = '';
      this.typeSync = '';
    },
  },
};
</script>
