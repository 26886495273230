<template>
   <v-flex>
    <v-data-table
      :headers="headers"
      :items="objetos"
      :search="search"
      sort-by="date"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{txtMessages.txtUsersTitle}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-text-field class="text-xs-center" v-model="search"
              append-icon="search" :label="txtMessages.txtSearch" single-line hide-details>
              </v-text-field>
            <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
              >
                {{txtMessages.txtNewItem}}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
               <v-form ref="form">
            <v-container>
                <v-row justify="space-between">
                <v-col
                    cols="12"
                    md="6"
                >
                <v-text-field
            v-model="name"
            :rules="[rules.required]"
            :label="txtMessages.txtUserName"
            required
          ></v-text-field>
          <template v-if="editedIndex === -1">
            <v-text-field
            v-model="email"
            :rules="[rules.email, rules.required]"
            :label="txtMessages.txtEmail"
            required
          ></v-text-field>
        </template>
        <template v-else>
            <v-text-field
            disabled
            v-model="email"
            :rules="[rules.email, rules.required]"
            :label="txtMessages.txtEmail"
            required
          ></v-text-field>
        </template>
          <template v-if="editedIndex === -1">
          <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            :label="txtMessages.lblUserPassword"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
          </template>
          <v-text-field
            v-model="lastName"
            :rules="[rules.required]"
            :label="txtMessages.lblLastName"
          ></v-text-field>
           <v-text-field
            v-model="userName"
            :rules="[rules.required]"
            :label="txtMessages.lblNickName"
            required
          ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
          <v-select
                v-model="rol"
                :items="logRol == 'pln' ?  rolListPLN : rolList"
                :label="txtMessages.lblSelectRol"
            ></v-select>
        <template v-if="editedIndex === -1">
            <v-select
                v-model="userType"
                :items="logRol == 'pln' ? typeList : typeListPLN"
                :label="txtMessages.lblSelectTypeUser"
            ></v-select>
        </template>
        <template v-else>
            <v-select
                disabled
                v-model="userType"
                :items="typeList"
                :label="txtMessages.lblSelectTypeUser"
            ></v-select>
        </template>
      </v-col>
      <v-flex xs12 sm12 md12 v-show="valida">
        <div class="red--text" v-for="v in validaMensaje" :key="v" v-text="v">
        </div>
    </v-flex>
    </v-row>
  </v-container>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn color="blue darken-1" text @click="reset">
        {{txtMessages.txtCancel}}
    </v-btn>
    <v-btn
    :disabled="!formIsValid"
    color="blue darken-1"
    text @click="guardar">
    {{txtMessages.txtSave}}
    </v-btn>
    </v-card-actions>
</v-form>
            </v-card>
          </v-dialog>
           <v-dialog v-model="aiModal" max-width="500px">
            <v-card>
            <v-card-title class="headline" v-if="aiAction == 1">
                {{txtMessages.txtConfirmActivate}} {{aiName}}?
            </v-card-title>
            <v-card-title class="headline" v-if="aiAction == 2">
                {{txtMessages.txtConfirmInactivate}} {{aiName}}?
            </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeaiModal">
                  {{txtMessages.txtCancel}}
                </v-btn>
                <v-btn v-if="aiAction == 1" color="orange darken-1" text @click="active">
                  {{txtMessages.txtActivate}}
                </v-btn>
                <v-btn v-if="aiAction == 2" color="orange darken-1" text @click="inactive">
                  {{txtMessages.txtInactivate}}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.created_at="{ item }">
        <td>
          {{$moment(item.created_at).format('YYYY-MM-DD HH:MM')}}
        </td>
      </template>
      <template v-slot:item.deleted="{ item }">
        <td>
          <div v-if="!item.deleted">
              <v-chip color="green" dark> {{txtMessages.txtActivate}} </v-chip>
          </div>
          <div v-else>
            <v-chip color="red" dark> {{txtMessages.txtInactivate}} </v-chip>
          </div>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
               <v-btn
              icon
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="editItem(item)"
            >
             <v-icon
              color="primary"
              class="mr-2"
              > mdi-pencil
            </v-icon>
            </v-btn>
          </template>
          <span>{{txtMessages.txtEdit}}</span>
        </v-tooltip>
         <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
              <template v-if="!item.deleted">
                  <v-btn
                    icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="activeInactiveShow(2, item)"
                    >
                    <v-icon
                    color="red"
                    class="mr-2"
                    > delete
                    </v-icon>
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                    icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="activeInactiveShow(1, item)"
                    >
                    <v-icon
                    color="green"
                    class="mr-2"
                    > restore_from_trash
                    </v-icon>
                </v-btn>
              </template>
          </template>
          <span>{{txtMessages.txtChangeStatus}}</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="listar()"
        >
          {{txtMessages.txtReset}}
        </v-btn>
      </template>
    </v-data-table>
    <notify-custom
   :showNotify="show"
   :type="tipoNotify"
   :message="msgNotify"
   :icontext="iconNotify"
   :color="color">
   </notify-custom>
    </v-flex>
</template>
<script>
import axios from 'axios';
import notifyCustom from './partials/notifyDialog.vue';
import langMsg from '../config/generalMessages';

export default {
  components: {
    'notify-custom': notifyCustom,
  },
  data() {
    return {
      objUser: null,
      logRol: null,
      txtMessages: langMsg,
      email: '',
      password: '',
      userName: '',
      name: '',
      lastName: '',
      rol: '',
      userType: '',
      rolList: ['admin', 'developer', 'dev', 'pln', 'comercial', 'support'],
      typeList: ['external-app', 'internal-app'],
      rolListPLN: ['pln'],
      typeListPLN: ['internal-app'],
      created_by: null,

      show1: false,
      idItem: '',
      valida: 0,
      validaMensaje: [],
      search: '',
      objetos: [],

      tipoNotify: 'Info',
      msgNotify: '--',
      show: false,
      color: 'primary',
      iconNotify: 'info',

      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
        min: v => v.length >= 8 || 'Min 8 characters',
      },

      headers: [
        {
          text: langMsg.txtCreationDate,
          align: 'start',
          value: 'created_at',
        },
        { text: langMsg.txtCreatedBy, value: 'created_by' },
        { text: 'rol', value: 'rol' },
        { text: 'status', value: 'deleted' },
        { text: langMsg.txtUserType, value: 'userType' },
        { text: 'nick', value: 'userName' },
        { text: langMsg.txtName, value: 'name' },
        { text: langMsg.txtLastName, value: 'lastName' },
        { text: 'email', value: 'email' },
        { text: langMsg.txtOptions, value: 'actions', sortable: false },
      ],

      dialog: false,
      editedIndex: -1,
      // eslint-disable-next-line
      aiModal: 0,
      aiAction: 0,
      aiName: '',
      aiId: '',
    };
  },
  computed: {
    formIsValid() {
      const condition = this.editedIndex === -1 ? this.name
              && this.email
              && this.userName
              && this.userType
              && this.rol
              && this.password
              && this.lastName
        : this.name
              && this.email
              && this.userName
              && this.rol
              && this.lastName;
      return condition;
    },
    formTitle() {
      return this.editedIndex === -1 ? 'Crear' : 'Editar';
    },
  },

  watch: {
    dialog(val) {
      const exp = val || this.close();
      return exp;
    },
  },

  created() {
    this.objUser = localStorage.getItem('user');
    axios.defaults.headers.common.authorization = this.objUser.token; // this.$store.state.token;
    this.logRol = this.objUser.rol; // this.$store.user.rol;
    this.created_by = this.objUser.email;
    this.$store.commit('SET_LAYOUT', 'principal-layout');
    this.listar();
  },

  methods: {
    notifyMessage(type, message) {
      this.tipoNotify = type;
      this.msgNotify = message;
      this.show = true;
      if (type === 'Info') {
        this.color = 'primary';
        this.iconNotify = 'info';
      } else if (type === 'Success') {
        this.color = 'green';
        this.icontext = 'check_circle';
      } else if (type === 'Error') {
        this.color = 'red';
        this.icontext = 'error';
      }
    },
    listar() {
      const me = this;
      me.objetos = [];
      axios.get('user/getmany').then((res) => {
        me.objetos = res.data;
      }).catch((err) => console.log(err));
    },
    validar() {
      this.valida = 0;
      this.validaMensaje = [];
      if (this.nameProject.length < 1) {
        this.validaMensaje.push('El nombre del proyecto es un campo requerido');
      }
      if (this.emailDev.length < 1) {
        this.validaMensaje.push('El email del desarrollador es un campo requerido');
      }
      if (this.validaMensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },
    reset() {
      this.$refs.form.reset();
    },
    clean() {
      this.valida = 0;
      this.validaMensaje = [];
      this.name = '';
      this.email = '';
      this.password = '';
      this.userName = '';
      this.lastName = '';
      this.rol = '';
      this.userType = '';
      this.errorMsg = '';
      this.showError = 0;
      this.successMsg = '';
      this.showSuccess = 0;
      this.editedIndex = -1;
    },
    inactive() {
      const me = this;
      // eslint-disable-next-line
      axios.put(`user/inactive/${this.idItem}`).then((response) => {
        me.aiModal = 0;
        me.aiName = '';
        me.idItem = '';
        me.listar();
      }).catch((err) => {
        console.log(err);
      });
    },
    active() {
      const me = this;
      // eslint-disable-next-line
      axios.put(`user/active/${this.idItem}`).then((response) => {
        me.aiModal = 0;
        me.aiName = '';
        me.idItem = '';
        me.listar();
      }).catch((err) => {
        console.log(err);
      });
    },
    guardar() {
      const me = this;
      if (this.editedIndex > -1) {
        // editar
        const editdoc = {
          userName: this.userName,
          name: this.name,
          lastName: this.lastName,
          rol: this.rol,
        };
        // eslint-disable-next-line
        axios.put(`user/update/${this.idItem}`, editdoc).then((response) => {
          me.clean();
          me.close();
          me.listar();
        }).catch((err) => {
          console.log(err);
          this.notifyMessage('Error', err.message);
        });
      } else {
        // nuevo
        const newdoc = {
          email: this.email,
          password: this.password,
          userName: this.userName,
          name: this.name,
          lastName: this.lastName,
          rol: this.rol,
          userType: this.userType,
          created_by: this.created_by,
        };
        axios.post('user/create', newdoc).then((response) => {
          me.clean();
          me.close();
          me.listar();
          return response.data;
        }).catch((err) => {
          console.log(err);
          this.notifyMessage('Error', err.message);
        });
      }
    },
    editItem(item) {
      // eslint-disable-next-line
      this.idItem = item._id;
      this.userName = item.userName;
      this.name = item.name;
      this.lastName = item.lastName;
      this.rol = item.rol;
      this.userType = item.userType;
      this.email = item.email;

      this.dialog = true;
      this.editedIndex = 1;
    },
    activeInactiveShow(action, item) {
      this.aiModal = 1;
      this.aiName = item.name;
      // eslint-disable-next-line
      this.idItem = item._id;
      if (action === 1) {
        this.aiAction = 1;
      } else if (action === 2) {
        this.aiAction = 2;
      } else {
        this.aiModal = 0;
      }
    },
    closeaiModal() {
      this.aiModal = 0;
      this.clean();
    },
    close() {
      this.dialog = false;
      this.clean();
    },
  },
};
</script>
