var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('notify-custom',{attrs:{"showNotify":_vm.show,"type":_vm.tipoNotify,"message":_vm.msgNotify,"icontext":_vm.iconNotify,"color":_vm.color}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"page":_vm.page,"pageCount":_vm.numberOfPages,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.objetos,"sort-by":"created_at"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.txtMessages.txtOrders)+" "+_vm._s(_vm.nameDropshipper))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"text-xs-center",attrs:{"append-icon":"search","label":_vm.txtMessages.txtSearch,"single-line":"","hide-details":""},on:{"change":function($event){return _vm.listar()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('vue-excel-xlsx',{attrs:{"data":_vm.objExcel,"columns":_vm.columns,"file-name":'ordersDropshipper',"file-type":'xlsx',"sheet-name":'orders'}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"primary","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" file_download ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.txtMessages.txtExport))])])],1)],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format('YYYY-MM-DD HH:MM'))+" ")])]}},{key:"item.cancelled_object.cancelled_at",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.cancelled)?[_vm._v(" "+_vm._s(_vm.$moment(item.cancelled_object.cancelled_at).format('YYYY-MM-DD HH:MM'))+" ")]:_vm._e()],2)]}},{key:"item.fulfilled",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.fulfilled),"dark":""}},[_vm._v(" "+_vm._s(item.fulfilled ? "fulfilled" : "unfulfilled")+" ")])]}},{key:"item.cancelled",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.cancelled),"dark":""}},[_vm._v(" "+_vm._s(item.cancelled)+" ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.listar()}}},[_vm._v(" "+_vm._s(_vm.txtMessages.txtReset)+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }