<template>
   <v-flex>
     <notify-custom
   :showNotify="show"
   :type="tipoNotify"
   :message="msgNotify"
   :icontext="iconNotify"
   :color="color">
   </notify-custom>
    <v-data-table
      :page="page"
      :pageCount="numberOfPages"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :headers="headers"
      :items="objetos"
      sort-by="created_at"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{txtMessages.txtOrders}} {{nameDropshipper}}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-text-field class="text-xs-center" v-model="search"
             append-icon="search" :label="txtMessages.txtSearch" single-line hide-details
             @change="listar()">
              </v-text-field>
            <v-spacer></v-spacer>
            <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
           <vue-excel-xlsx
              :data="objExcel"
              :columns="columns"
              :file-name="'ordersDropshipper'"
              :file-type="'xlsx'"
              :sheet-name="'orders'"
              >
              <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                class="ma-2 white--text"
                fab
                small
                v-bind="attrs"
                v-on="on"
              >
              <v-icon dark>
                file_download
              </v-icon>
            </v-btn>
              </template>
              <span>{{txtMessages.txtExport}}</span>
            </v-tooltip>
          </vue-excel-xlsx>
        </v-toolbar>
      </template>
      <template v-slot:item.created_at="{ item }">
        <td>
          {{$moment(item.created_at).format('YYYY-MM-DD HH:MM')}}
        </td>
      </template>
      <template v-slot:item.cancelled_object.cancelled_at="{ item }">
        <td>
           <template v-if="item.cancelled">
          {{$moment(item.cancelled_object.cancelled_at).format('YYYY-MM-DD HH:MM')}}
        </template>
        </td>
      </template>
      <template v-slot:item.fulfilled="{ item }">
      <v-chip
        :color="getColor(item.fulfilled)"
        dark
      >
        {{ item.fulfilled ? "fulfilled" : "unfulfilled" }}
      </v-chip>
    </template>
      <template v-slot:item.cancelled="{ item }">
      <v-chip
        :color="getColor(item.cancelled)"
        dark
      >
        {{ item.cancelled }}
      </v-chip>
    </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="listar()"
        >
         {{txtMessages.txtReset}}
        </v-btn>
      </template>
    </v-data-table>
    </v-flex>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
import notifyCustom from './partials/notifyDialog.vue';
import langMsg from '../config/generalMessages';

export default {
  components: {
    'notify-custom': notifyCustom,
  },
  data() {
    return {
      txtMessages: langMsg,
      dialog: false,
      search: '',
      objetos: [],
      objExcel: [],
      dialogDelete: false,
      page: 1,
      numberOfPages: 10,
      options: {},
      totalItems: 0,
      headers: [
        {
          text: langMsg.txtCreationDate, align: 'start', value: 'created_at', sortable: true,
        },
        { text: langMsg.txtOrderNameProvider, value: 'origin_order_name', sortable: true },
        { text: langMsg.txtorderNameDropshipper, value: 'final_order_name', sortable: true },
        { text: langMsg.txtFulfilled, value: 'fulfilled', sortable: false },
      ],
      // { text: langMsg.txtCancelled, value: 'cancelled', sortable: false },
      // { text: langMsg.txtCancelledReason, value: 'cancelled_object.reason', sortable: false },
      // { text: langMsg.txtCancelledDate,
      // value: 'cancelled_object.cancelled_at', sortable: false },
      columns: [
        { label: langMsg.txtCreationDate, field: 'created_at' },
        { label: langMsg.txtOrderName, field: 'origin_order_name' },
        { label: langMsg.txtOrder, field: 'final_order_name' },
        { label: langMsg.txtFulfilled, field: 'fulfilled' },
        { label: langMsg.txtCancelled, field: 'cancelled' },
      ],
      // eslint-disable-next-line
      nameDropshipper: '',
      nameConfig: '',
      idDropshipper: '',
      data: {},
      // --- events data ---
      loading: false,

      tipoNotify: 'Info',
      msgNotify: '--',
      show: false,
      color: 'primary',
      iconNotify: 'info',
    };
  },
  computed: {
  },
  watch: {
    dialog(val) {
      const exp = val || this.close();
      return exp;
    },
    dialogDelete(val) {
      const exp = val || this.closeDelete();
      return exp;
    },
    options: {
      handler() {
        this.listar();
      },
    },
    deep: true,
  },
  created() {
    this.$store.commit('SET_LAYOUT', 'principal-layout');
  },
  mounted() {
    axios.defaults.headers.common.authorization = this.$store.state.token;
    this.idDropshipper = this.$route.params.id;
    this.getOne(this.idDropshipper);
    // this.listar();
  },
  methods: {
    sortByDate(a, b) {
      return new Date(a.fecha) - new Date(b.fecha);
    },
    notifyMessage(type, message) {
      this.tipoNotify = type;
      this.msgNotify = message;
      this.show = true;
      if (type === 'Info') {
        this.color = 'primary';
        this.iconNotify = 'info';
      } else if (type === 'Success') {
        this.color = 'green';
        this.icontext = 'check_circle';
      } else if (type === 'Error') {
        this.color = 'red';
        this.icontext = 'error';
      }
    },
    getOne(id) {
      const me = this;
      axios.get(`dropshipper/get/${id}`).then((res) => {
        me.data = res.data.body;
        me.nameDropshipper = `${me.data.name} - ${me.data.contact.name}`;
        me.nameConfig = me.data.config.shop;
        return 'ok';
      }).catch((err) => console.log('my err:', err));
    },
    listar() {
      const me = this;
      me.loading = true;
      const {
        page,
        itemsPerPage,
        sortBy,
        sortDesc,
      } = me.options;
      const items = itemsPerPage || 100;
      const pageNumber = page || 1;
      this.idDropshipper = this.$route.params.id;
      me.objetos = [];
      let urlConsult = `myorder/get/dropshipper/${this.idDropshipper}?limit=${items}&page=${pageNumber}`;
      const search = me.search !== '' ? me.search : false;
      if (search) urlConsult = `${urlConsult}&search=${search}`;
      if (sortBy.length > 0) urlConsult = `${urlConsult}&sort=${sortBy[0]}`;
      if (sortDesc.length > 0) urlConsult = `${urlConsult}&typesort=${sortDesc[0]}`;
      axios.get(urlConsult).then((res) => {
        me.loading = false;
        me.objetos = res.data.body.docs;
        me.objExcel = res.data.body.docs.map((a) => {
          const doc = { ...a };
          doc.fulfilled = doc.fulfilled ? 'fulfilled' : 'unfulfilled';
          doc.created_at = moment(a.created_at).format('YYYY-MM-DD HH:MM');
          return doc;
        });
        me.totalItems = res.data.body.totalDocs;
        me.numberOfPages = res.data.body.pages;
      }).catch((err) => console.log('my err:', err));
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    clean() {
      const empty = '';
      this.nameDropshipper = empty;
    },
    getColor(result) {
      let color = 'gray';
      if (result) color = 'green';
      else color = 'red';
      return color;
    },
    dateFormatCancelled(value) {
      let result = '';
      if (value) {
        if (value.reason && value.cancelled_at) result = value.cancelled_at;
      }
      return result;
    },
    reasonFormat(value) {
      let result = '';
      if (value) {
        if (value.reason) result = value;
      }
      return result;
    },
  },
};
</script>
