<template>
    <v-dialog v-model="showNotify" max-width="600px">
      <v-card class="mx-auto" :color="color" dark>
        <v-card-title>
          <v-icon large left>
            {{icontext}}
          </v-icon>
         {{type}}
        </v-card-title>
        <v-card-text>{{message}}</v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props: {
    showNotify: { type: Boolean, default: false },
    type: { type: String, default: 'Info' },
    message: { type: String, default: 'Message' },
    color: { type: String, default: 'primary' },
    icontext: { type: String, default: 'info' },
  },
};
</script>
