var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('notify-custom',{attrs:{"showNotify":_vm.show,"type":_vm.tipoNotify,"message":_vm.msgNotify,"icontext":_vm.iconNotify,"color":_vm.color}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"page":_vm.page,"pageCount":_vm.numberOfPages,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.objetos,"sort-by":"created_at"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.txtMessages.txtOrdersNoSync)+" "+_vm._s(_vm.nameDropshipper))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"text-xs-center",attrs:{"append-icon":"search","label":_vm.txtMessages.txtSearch,"single-line":"","hide-details":""},on:{"change":function($event){return _vm.listar()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('vue-excel-xlsx',{attrs:{"data":_vm.objExcel,"columns":_vm.columns,"file-name":'ordersDropshipper',"file-type":'xlsx',"sheet-name":'orders'}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"primary","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" file_download ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.txtMessages.txtExport))])])],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.showModalSync),callback:function ($$v) {_vm.showModalSync=$$v},expression:"showModalSync"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" RESULT ")]),_c('v-card-text',[_vm._v("MSG: "+_vm._s(_vm.resultSync.message))]),_c('v-card-text',[_vm._v("TOTAL ORDER "+_vm._s(_vm.resultSync.totalOrder))]),_c('v-card-text',[_vm._v("SKUS "+_vm._s(_vm.resultSync.skusOrder))]),_c('v-card-actions',[_c('v-spacer'),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format('YYYY-MM-DD HH:MM'))+" ")])]}},{key:"item.register_logs",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('h5',[_vm._v(_vm._s(item.register_logs.length >= 1 ? item.register_logs[0].date : ''))]),_vm._v(" "+_vm._s(item.register_logs.length >= 1 ? item.register_logs[0].content : '')+" ("+_vm._s(item.register_logs.length)+") ")])]}},{key:"item.shipping_lines",fn:function(ref){
var item = ref.item;
return [_c('td',_vm._l((item.shipping_lines),function(v){return _c('p',{key:v},[_vm._v(_vm._s(v.code))])}),0)]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"icon":"","color":"#ff9800","loading":item.loading,"disabled":item.loading},on:{"click":function($event){return _vm.syncOrderDropshipper(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-sync ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.txtMessages.txtSyncOrder))])])]]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.listar()}}},[_vm._v(" "+_vm._s(_vm.txtMessages.txtReset)+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }