<template>
  <component :is="layout"></component>
</template>
<script>
import { mapState } from 'vuex';
import LoginLayout from './LayoutLogin.vue';
import PrincipalLayout from './LayoutMain.vue';

export default {
  components: { LoginLayout, PrincipalLayout },
  computed: mapState(['layout']),
  created() {
    const { shop } = this.$route.query;
    if (shop) localStorage.setItem('lang', 'english');
  },
};
</script>
