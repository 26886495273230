var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[(_vm.logged)?_c('v-navigation-drawer',{attrs:{"clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"dense":""}},[[(_vm.rol === 'admin' || _vm.rol === 'developer'
        || _vm.rol === 'dev')?_c('v-list-item',{attrs:{"to":{ name: 'Config' }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("settings")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.txtMessages.configApp)+" ")])],1):_vm._e(),( _vm.rol !== 'datafeed')?_c('v-list-item',{attrs:{"to":{ name: 'Stats' }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("leaderboard")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.txtMessages.generalStats)+" ")])],1):_vm._e()],[(_vm.rol === 'admin' || _vm.rol === 'developer'
        || _vm.rol === 'dev')?_c('v-list-item',{attrs:{"to":{ name: 'CreateDropshipper' }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("store")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.txtMessages.createDropshipper)+" ")])],1):_vm._e(),(_vm.rol === 'admin' || _vm.rol === 'developer'
        || _vm.rol === 'dev')?_c('v-list-item',{attrs:{"to":{ name: 'CreateShop' }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("store")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.txtMessages.createDropshipperSimple)+" ")])],1):_vm._e(),(_vm.rol === 'admin' || _vm.rol === 'developer'
        || _vm.rol === 'dev')?_c('v-list-item',{attrs:{"to":{ name: 'ListUser' }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("people")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.txtMessages.listUser)+" ")])],1):_vm._e(),( _vm.rol !== 'datafeed')?_c('v-list-item',{attrs:{"to":{ name: 'DropshipperList' }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("list")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.txtMessages.listDropshipper)+" ")])],1):_vm._e(),( _vm.rol !== 'datafeed')?_c('v-list-item',{attrs:{"to":{ name: 'LogsGeneral' }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("highlight")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.txtMessages.listLogsGeneral)+" ")])],1):_vm._e(),(_vm.rol === 'admin' || _vm.rol === 'developer'
        || _vm.rol === 'datafeed' || _vm.rol === 'dev')?_c('v-list-item',{attrs:{"to":{ name: 'VariantsPLN' }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("category")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.txtMessages.plnVariants)+" ")])],1):_vm._e(),(_vm.rol === 'developer'
        || _vm.rol === 'datafeed' || _vm.rol === 'dev')?_c('v-list-item',{attrs:{"to":{ name: 'VariantsDropshipper' }}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("category")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.txtMessages.dropshipperVariants)+" ")])],1):_vm._e()]],2)],1):_vm._e(),_c('v-app-bar',{attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"app":"","color":"primary","dark":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',{staticClass:"ml-0 pl-4",staticStyle:{"width":"300px"}},[_c('span',{staticClass:"hidden-sm-and-down"},[_vm._v("CATALOG CONNECT")])]),_c('v-spacer'),(_vm.logged)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_vm._v(" "+_vm._s(_vm.name)+" "),_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("account_circle")])],1)]}}],null,false,1168911498)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{attrs:{"large":""}},[_vm._v("account_circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.email))]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.name))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.rol))])],1),_c('v-list-item-action')],1)],1)],1):_vm._e(),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),(_vm.logged)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.logout()}}},[_c('v-icon',[_vm._v(" logout ")])],1):_c('v-btn',{attrs:{"to":{ name: 'Login' },"icon":""}},[_c('v-icon',[_vm._v(" login ")])],1)],1),_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('v-slide-y-transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }