<template>
<v-layout align-center justify-center>
    <notify-custom
   :showNotify="show"
   :type="tipoNotify"
   :message="msgNotify"
   :icontext="iconNotify"
   :color="color">
   </notify-custom>
    <v-flex xs12 sm8 lg5 xl4>
        <v-card v-if="showReset">
            <v-toolbar dark color="grey darken-3">
                <v-toolbar-title>
                    Reset your password
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              type your new password
                <v-text-field
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            required
            label="User Password"
            :rules="[rules.min, rules.required]"
            @click:append="show1 = !show1"
          ></v-text-field>
            </v-card-text>
            <v-card-actions class="px-3 pb-3">
                <v-flex class="text-xs-right">
                    <v-btn
                    :loading="loading"
                    :disabled="loading"
                     @click="savePass()" color="primary">reset password</v-btn>
                </v-flex>
            </v-card-actions>
        </v-card>
    </v-flex>
</v-layout>
</template>

<script>
import axios from 'axios';
import notifyCustom from './partials/notifyDialog.vue';
import langMsg from '../config/generalMessages';

export default {
  components: {
    'notify-custom': notifyCustom,
  },
  data() {
    return {
      tipoNotify: 'Info',
      msgNotify: '--',
      show: false,
      color: 'primary',
      iconNotify: 'info',

      txtMessages: langMsg,
      password: '',
      errorm: null,
      show1: false,
      showReset: false,
      resetPassToken: '',
      token: '',
      loading: false,
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
        min: v => v.length >= 8 || 'Min 8 characters',
      },
    };
  },
  created() {
    this.$store.commit('SET_LAYOUT', 'login-layout');
    this.token = this.$route.query.token;
    axios.get(`reset/${this.token}`).then((response) => {
      const { data } = response;
      this.resetPassToken = data.resetPasswordToken;
      this.showReset = true;
      this.token = '';
    }).catch((err) => {
    // throw err;
      console.log(err);
      this.errorm = null;
      this.showReset = false;
      this.notifyMessage('Error', err.message);
    });
  },
  methods: {
    notifyMessage(type, message) {
      this.tipoNotify = type;
      this.msgNotify = message;
      this.show = true;
      if (type === 'Info') {
        this.color = 'primary';
        this.iconNotify = 'info';
      } else if (type === 'Success') {
        this.color = 'green';
        this.icontext = 'check_circle';
      } else if (type === 'Error') {
        this.color = 'red';
        this.icontext = 'error';
      }
    },
    savePass() {
      this.loading = false;
      axios.post(`/updatepass/${this.resetPassToken}`, { password: this.password }).then((response) => {
        const { data } = response;
        if (data) this.notifyMessage('Success', data.msg);
        this.clean();
      }).catch((err) => {
        // throw err;
        this.errorm = null;
        this.notifyMessage('Error', err.message);
        this.loading = false;
        this.clean();
      });
    },
    clean() {
      this.password = '';
      this.resetPassToken = '';
      this.token = '';
    },
  },
};
</script>
