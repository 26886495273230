<template>
  <v-app id="inspire">
    <v-navigation-drawer :clipped="$vuetify.breakpoint.lgAndUp" app v-model="drawer" v-if="logged">
      <!--  -->
      <v-list dense>
        <template>
          <v-list-item v-if="rol === 'admin' || rol === 'developer'
          || rol === 'dev'" :to="{ name: 'Config' }">
            <v-list-item-action>
              <v-icon>settings</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              {{ txtMessages.configApp }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if=" rol !== 'datafeed'" :to="{ name: 'Stats' }">
            <v-list-item-action>
              <v-icon>leaderboard</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              {{ txtMessages.generalStats }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <template>
          <v-list-item v-if="rol === 'admin' || rol === 'developer'
          || rol === 'dev'" :to="{ name: 'CreateDropshipper' }">
            <v-list-item-action>
              <v-icon>store</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              {{ txtMessages.createDropshipper }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="rol === 'admin' || rol === 'developer'
          || rol === 'dev'" :to="{ name: 'CreateShop' }">
            <v-list-item-action>
              <v-icon>store</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              {{ txtMessages.createDropshipperSimple }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="rol === 'admin' || rol === 'developer'
          || rol === 'dev'" :to="{ name: 'ListUser' }">
            <v-list-item-action>
              <v-icon>people</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              {{ txtMessages.listUser }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if=" rol !== 'datafeed'" :to="{ name: 'DropshipperList' }">
            <v-list-item-action>
              <v-icon>list</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              {{  txtMessages.listDropshipper }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if=" rol !== 'datafeed'" :to="{ name: 'LogsGeneral' }">
            <v-list-item-action>
              <v-icon>highlight</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              {{  txtMessages.listLogsGeneral }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="rol === 'admin' || rol === 'developer'
          || rol === 'datafeed' || rol === 'dev'"
          :to="{ name: 'VariantsPLN' }">
            <v-list-item-action>
              <v-icon>category</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              {{  txtMessages.plnVariants }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item v-if="rol === 'developer'
          || rol === 'datafeed' || rol === 'dev'"
          :to="{ name: 'VariantsDropshipper' }">
            <v-list-item-action>
              <v-icon>category</v-icon>
            </v-list-item-action>
            <v-list-item-title>
              {{  txtMessages.dropshipperVariants }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="primary" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <span class="hidden-sm-and-down">CATALOG CONNECT</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu v-if="logged" offset-y>
        <template v-slot:activator="{ on, attrs }">
          {{ name }}
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>account_circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon large>account_circle</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ email }}</v-list-item-title>
              <v-list-item-title>{{ name }}</v-list-item-title>
              <v-list-item-subtitle>{{ rol }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-btn @click="logout()" icon v-if="logged">
        <v-icon>
          logout
        </v-icon>
      </v-btn>
      <v-btn :to="{ name: 'Login' }" icon v-else>
        <v-icon>
          login
        </v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-slide-y-transition mode="out-in">
          <router-view />
        </v-slide-y-transition>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import langMsg from './config/generalMessages';

export default {
  name: 'App',
  data: () => ({
    drawer: true,
    rol: '',
    email: '',
    name: '',
    dialog: false,
    items: [],
    txtMessages: langMsg,
  }
  ),
  computed: {
    logged() {
      this.completeData();
      return this.$store.state.user;
    },
  },
  watch: {
    dialog(val) {
      const exp = val || this.close();
      return exp;
    },
  },
  created() {
    this.$store.dispatch('autoLogin');
  },
  methods: {
    completeData() {
      this.email = this.$store.state.user.email;
      this.rol = this.$store.state.user.rol;
      this.name = this.$store.state.user.name;
    },
    close() {
      this.dialog = false;
    },
    logout() {
      this.$store.dispatch('logout');
      this.email = '';
      this.rol = '';
      this.name = '';
    },
  },
};
</script>
