import Vue from 'vue';
import VueRouter from 'vue-router';
// eslint-disable-next-line import/no-cycle
// import store from '../store/index';
import Stats from '../views/Stats.vue';
import Forbidden from '../views/errors/403Forbidden.vue';
import Logs from '../components/LogsList.vue';
import LogsGeneral from '../components/LogGeneral.vue';
import Login from '../components/Login.vue';
import Home from '../components/Home.vue';
import Config from '../config/index';
import Forgot from '../components/ForgotPass.vue';
import Reset from '../components/ResetPass.vue';

import DropshipperRegister from '../components/DropshiperRegister.vue';
import DropshipperRegisterTest from '../components/DropshiperRegisterTest.vue';
import CreateDropshipper from '../components/CreateDropshipper.vue';
import DropshipperList from '../components/DropshipperList.vue';
import DropshipperListData from '../components/DropshipperListData.vue';
import OrderList from '../components/OrderList.vue';
import OrderListNoSynced from '../components/OrderListNoSynced.vue';
import ListUser from '../components/ListUser.vue';
import ListProducts from '../components/ListProducts.vue';
import ConfigApp from '../components/ConfigGeneral.vue';
import VariantsPLN from '../components/VariantsPLN.vue';
import VariantsDropshipper from '../components/VariantsDropshipper.vue';
import AuthGuard from '../utils/authGuard';

Vue.use(VueRouter);

const routes = [
  {
    path: '/app/',
    name: 'Home',
    component: Home,
    props: true,
    meta: {
      free: true,
    },
  },
  {
    path: '/',
    name: 'MyHome',
    component: Home,
    props: true,
    meta: {
      free: true,
    },
  },
  {
    path: '/app/forgot/pass',
    name: 'Forgot',
    component: Forgot,
    props: true,
    meta: {
      free: true,
    },
  },
  {
    path: '/app/reset',
    name: 'Reset',
    component: Reset,
    props: true,
    meta: {
      free: true,
    },
  },
  {
    path: '/app/stats',
    name: 'Stats',
    component: Stats,
    props: true,
    meta: {
      loginRequired: true,
      admin: true,
      pln: true,
      developer: true,
      dev: true,
      comercial: true,
      support: true,
    },
  },
  {
    path: '/app/config',
    name: 'Config',
    component: ConfigApp,
    props: true,
    meta: {
      loginRequired: true,
      admin: false,
      developer: true,
      dev: true,
    },
  },
  {
    path: '/app/403',
    name: 'forbidden',
    component: Forbidden,
    props: true,
    meta: {
      free: true,
    },
  },
  {
    path: '/app/login',
    name: 'Login',
    component: Login,
    props: true,
    meta: {
      free: true,
    },
  },
  {
    path: '/app/logs/:id',
    name: 'Logs',
    component: Logs,
    props: true,
    meta: {
      loginRequired: true,
      admin: true,
      pln: true,
      developer: true,
      dev: true,
      comercial: true,
      support: true,
    },
  },
  {
    path: '/app/logs-general',
    name: 'LogsGeneral',
    component: LogsGeneral,
    props: true,
    meta: {
      loginRequired: true,
      admin: true,
      pln: true,
      developer: true,
      dev: true,
      comercial: true,
      support: true,
    },
  },
  {
    path: '/app/orders/:id',
    name: 'Orders',
    component: OrderList,
    props: true,
    meta: {
      loginRequired: true,
      admin: true,
      developer: true,
      pln: true,
      dev: true,
      comercial: true,
      support: true,
    },
  },
  {
    path: '/app/orders-no-synced/:id',
    name: 'Orders No Synced',
    component: OrderListNoSynced,
    props: true,
    meta: {
      loginRequired: true,
      admin: true,
      developer: true,
      pln: false,
      dev: true,
      comercial: true,
      support: true,
    },
  },
  {
    path: '/app/products/:id/:name',
    name: 'List Products',
    component: ListProducts,
    props: true,
    meta: {
      loginRequired: true,
      admin: true,
      developer: true,
      pln: false,
      dev: true,
      comercial: false,
      support: false,
    },
  },
  {
    path: '/app/create/dropshipper',
    name: 'CreateDropshipper',
    component: DropshipperRegister,
    props: true,
    meta: {
      loginRequired: true,
      admin: false,
      developer: true,
      pln: false,
      dev: true,
      comercial: false,
      support: false,
    },
  },
  {
    path: '/app/create/dropshipper/test',
    name: 'CreateDropshipperTest',
    component: DropshipperRegisterTest,
    props: true,
    meta: {
      loginRequired: true,
      admin: false,
      developer: true,
      pln: false,
      dev: true,
      comercial: false,
      support: false,
    },
  },
  {
    path: '/app/create/simple/dropshipper',
    name: 'CreateShop',
    component: CreateDropshipper,
    props: true,
    meta: {
      loginRequired: true,
      admin: false,
      developer: true,
      dev: true,
    },
  },
  {
    path: '/app/list/dropshipper',
    name: 'DropshipperList',
    component: DropshipperList,
    props: true,
    meta: {
      loginRequired: true,
      admin: true,
      developer: true,
      pln: true,
      dev: true,
      comercial: true,
      support: true,
    },
  },
  {
    path: '/app/list/dropshipper-data',
    name: 'DropshipperListData',
    component: DropshipperListData,
    props: true,
    meta: {
      loginRequired: true,
      admin: true,
      developer: true,
      dev: true,
      data: true,
    },
  },
  {
    path: '/app/list/user',
    name: 'ListUser',
    component: ListUser,
    props: true,
    meta: {
      loginRequired: true,
      developer: true,
      dev: true,
    },
  },
  {
    path: '/app/variants/pln',
    name: 'VariantsPLN',
    component: VariantsPLN,
    props: true,
    meta: {
      loginRequired: true,
      admin: true,
      developer: true,
      datafeed: true,
      dev: true,
    },
  },
  {
    path: '/app/variants/dropshipper',
    name: 'VariantsDropshipper',
    component: VariantsDropshipper,
    props: true,
    meta: {
      loginRequired: true,
      admin: true,
      developer: true,
      datafeed: true,
      dev: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: Config.config.locale,
  routes,
});
router.beforeEach(AuthGuard);
// router.beforeEach((to, from, next) => {
//   const user = JSON.parse(localStorage.getItem('user'));
//   if (to.matched.some(record => record.meta.free)) {
//     next();
//   } else if (user) {
//     switch (user.rol) {
//       case 'admin':
//         // code block
//         if (to.matched.some(record => record.meta.admin)) {
//           next();
//         } else {
//           next({ name: 'forbidden' });
//         }
//         break;
//       case 'developer':
//         // code block
//         if (to.matched.some(record => record.meta.developer)) {
//           next();
//         } else {
//           next({ name: 'forbidden' });
//         }
//         break;
//       case 'pln':
//         // code block
//         if (to.matched.some(record => record.meta.pln)) {
//           next();
//         } else {
//           next({ name: 'forbidden' });
//         }
//         break;
//       default:
//         // code block
//         next({ name: 'forbidden' });
//     }
//   } else {
//     next({ name: 'Login' });
//   }
// });
export default router;
