<template>
<v-layout align-center justify-center>
     <notify-custom
   :showNotify="show"
   :type="tipoNotify"
   :message="msgNotify"
   :icontext="iconNotify"
   :color="color">
   </notify-custom>
    <v-flex xs12 sm8 lg5 xl4>
        <v-card>
            <v-toolbar dark color="grey darken-3">
                <v-toolbar-title>
                    {{txtMessages.txtForgotTitle}}
                </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                {{txtMessages.txtInstructionsForgot}}
                <v-text-field v-model="email"
                autofocus color="accent" :label="txtMessages.txtEmail" required>
                </v-text-field>
            </v-card-text>
            <v-card-actions class="px-3 pb-3">
                <v-flex class="text-xs-right">
                    <v-btn
                    :loading="loading"
                    :disabled="loading"
                    @click="resetPassword()"
                    color="primary">{{txtMessages.txtSendEmail}}</v-btn>
                </v-flex>
            </v-card-actions>
        </v-card>
    </v-flex>
</v-layout>
</template>

<script>
import axios from 'axios';
import notifyCustom from './partials/notifyDialog.vue';
import langMsg from '../config/generalMessages';

export default {
  components: {
    'notify-custom': notifyCustom,
  },
  data() {
    return {
      txtMessages: langMsg,
      email: '',
      errorm: null,
      show1: false,
      showReset: false,
      loading: false,

      tipoNotify: 'Info',
      msgNotify: '--',
      show: false,
      color: 'primary',
      iconNotify: 'info',
    };
  },
  created() {
    this.$store.commit('SET_LAYOUT', 'login-layout');
  },
  methods: {
    notifyMessage(type, message) {
      this.tipoNotify = type;
      this.msgNotify = message;
      this.show = true;
      if (type === 'Info') {
        this.color = 'primary';
        this.iconNotify = 'info';
      } else if (type === 'Success') {
        this.color = 'green';
        this.icontext = 'check_circle';
      } else if (type === 'Error') {
        this.color = 'red';
        this.icontext = 'error';
      }
    },
    resetPassword() {
      this.loading = true;
      axios.post('send-email', { email: this.email }).then((response) => {
        const { data } = response;
        if (data.title === 'Success') this.notifyMessage('Success', 'recovery email sent. please check the recovery link sent to your email');
        this.email = '';
        this.loading = false;
      }).catch((err) => {
        // throw err;
        this.loading = false;
        this.errorm = null;
        this.onError(err);
        this.notifyMessage('Error', err.message);
      });
    },
  },
};
</script>
