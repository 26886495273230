<template>
  <v-flex>
    <notify-custom :showNotify="show" :type="tipoNotify"
    :message="msgNotify" :icontext="iconNotify" :color="color">
    </notify-custom>
    <v-data-table :page="page" :pageCount="numberOfPages"
    :options.sync="options" :server-items-length="totalItems"
      :search="search" :loading="loading" :headers="headers"
      :items="objetos" :footer-props="propsTextFooterDataTable"
      class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Variants Dropshipper {{ name }}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
            class="text-xs-center"
            v-model="search"
            append-icon="search"
            :label="txtMessages.txtSearch"
            single-line
            hide-details
            @change="listar()"
            >
          </v-text-field>
          <v-spacer></v-spacer>
          <v-divider class="mx-4" inset vertical></v-divider>
          <!-- <vue-excel-xlsx
              :data="objExcel"
              :columns="columns"
              :file-name="'logsList'"
              :file-type="'xlsx'"
              :sheet-name="'logs'"
              >
              <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                class="ma-2 white--text"
                fab
                small
                v-bind="attrs"
                v-on="on"
              >
              <v-icon dark>
                file_download
              </v-icon>
            </v-btn>
              </template>
              <span>{{txtMessages.txtExport}}</span>
            </v-tooltip>
          </vue-excel-xlsx> -->
        </v-toolbar>
        <v-toolbar flat>
          <v-toolbar-title>
            <small>
            </small>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item.variant="{ item }">
        <td>
          <div v-if="item.variant">
            <v-chip color="green" dark> Sincronizado </v-chip>
          </div>
          <div v-else>
            <v-chip color="red" dark> No existe en el Drsh </v-chip>
          </div>
        </td>
      </template>
      <template v-slot:item.variant.sku="{ item }">
        <td>
          <div v-if="item.variant">
            {{ item.variant.sku || 'N/A' }}
          </div>
          <div v-else>
            {{ 'N/A' }}
          </div>
        </td>
      </template>
      <template v-slot:item.variant.updated_at="{ item }">
        <td>
          <div v-if="item.variant">
            {{ $moment(item.variant.updated_at).format('YYYY-MM-DD HH:MM') }}
          </div>
          <div v-else>
            {{ 'N/A' }}
          </div>
        </td>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn icon class="mr-2" color="blue" v-bind="attrs" v-on="on" @click="sincProduct(item)">
          sinc. producto
        </v-btn>
      </template>

      <template v-slot:no-data>
        <v-btn color="primary" @click="listar()">
          {{ txtMessages.txtReset }}
        </v-btn>
      </template>
    </v-data-table>
  </v-flex>
</template>
<script>
import axios from 'axios';
import notifyCustom from './partials/notifyDialog.vue';
import langMsg from '../config/generalMessages';

export default {
  components: {
    'notify-custom': notifyCustom,
  },
  data() {
    return {
      name: '',
      txtMessages: langMsg,
      propsTextFooterDataTable: {
        itemsPerPageText: 'Rows per page',
        itemsPerPageAllText: 'All',
      },
      dialog: false,
      search: '',
      objetos: [],
      objExcel: [],
      dialogDelete: false,
      page: 1,
      numberOfPages: 10,
      options: {},
      totalItems: 0,
      headers: [
        { text: 'update', value: 'variant.updated_at', sortable: false },
        { text: 'sku pln', value: 'sku', sortable: true },
        { text: 'sku drsh', value: 'variant.sku', sortable: true },
        { text: 'inv drsh', value: 'variant.inventory_dropshipper', sortable: true },
        { text: 'inv pln', value: 'variant.inventory_provider', sortable: true },
        { text: 'status', value: 'variant', sortable: false },
        { text: langMsg.txtToSync, value: 'actions', sortable: false },
      ],
      columns: [
      ],
      // eslint-disable-next-line
      data: {},
      // --- events data ---
      loading: false,
      tipoNotify: 'Info',
      msgNotify: '--',
      show: false,
      color: 'primary',
      iconNotify: 'info',
    };
  },
  computed: {
  },
  watch: {
    dialog(val) {
      const exp = val || this.close();
      return exp;
    },
    dialogDelete(val) {
      const exp = val || this.closeDelete();
      return exp;
    },
    options: {
      handler() {
        this.listar();
      },
    },
    deep: true,
  },
  created() {
    axios.defaults.headers.common.authorization = this.$store.state.token;
    this.$store.commit('SET_LAYOUT', 'principal-layout');
    this.listar();
  },

  methods: {
    notifyMessage(type, message) {
      this.tipoNotify = type;
      this.msgNotify = message;
      this.show = true;
      if (type === 'Info') {
        this.color = 'primary';
        this.iconNotify = 'info';
      } else if (type === 'Success') {
        this.color = 'green';
        this.icontext = 'check_circle';
      } else if (type === 'Error') {
        this.color = 'red';
        this.icontext = 'error';
      }
    },
    listar() {
      const me = this;
      me.loading = true;
      const { page, itemsPerPage } = me.options;
      const items = itemsPerPage || 10;
      const pageNumber = page || 1;
      const search = me.search !== '' ? me.search : false;
      this.idDropshipper = this.$route.params.id;
      this.name = this.$route.params.name;
      let urlConsult = `product/compare-products/${this.idDropshipper}?limit=${items}&page=${pageNumber}`;
      if (search) urlConsult = `${urlConsult}&search=${search}`;
      axios.get(urlConsult).then((res) => {
        me.loading = false;
        me.objetos = res.data.docs;
        me.totalItems = res.data.totalDocs;
        me.numberOfPages = res.data.pages;
      }).catch((err) => console.log('my err:', err));
    },
    sincProduct(item) {
      const urlConsult = `product/sync/single/${item.product_id}/${this.idDropshipper}`;
      axios.get(urlConsult).then((res) => {
        console.log(res);
      }).catch((err) => console.log('my err:', err));
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    clean() {
      const empty = '';
      this.nameDropshipper = empty;
    },
  },
};
</script>
