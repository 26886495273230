var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',[_c('notify-custom',{attrs:{"showNotify":_vm.show,"type":_vm.tipoNotify,"message":_vm.msgNotify,"icontext":_vm.iconNotify,"color":_vm.color}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"page":_vm.page,"pageCount":_vm.numberOfPages,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.objetos},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Logs "+_vm._s(_vm.nameDropshipper))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"text-xs-center",attrs:{"append-icon":"search","label":_vm.txtMessages.txtSearch,"single-line":"","hide-details":""},on:{"change":function($event){return _vm.listar()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('vue-excel-xlsx',{attrs:{"data":_vm.objExcel,"columns":_vm.columns,"file-name":'logsList',"file-type":'xlsx',"sheet-name":'logs'}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 white--text",attrs:{"color":"primary","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" file_download ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.txtMessages.txtExport))])])],1)],1),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('small')]),_c('v-spacer'),_c('v-spacer'),_c('v-select',{attrs:{"items":_vm.itemsType,"label":_vm.txtMessages.lblSelectTypeLog},on:{"change":function($event){return _vm.listar()}},model:{value:(_vm.typeLogs),callback:function ($$v) {_vm.typeLogs=$$v},expression:"typeLogs"}}),_c('v-spacer'),_c('v-select',{attrs:{"items":_vm.itemsTitle,"label":_vm.txtMessages.lblSelectTitleLog},on:{"change":function($event){return _vm.listar()}},model:{value:(_vm.titleLogs),callback:function ($$v) {_vm.titleLogs=$$v},expression:"titleLogs"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format('YYYY-MM-DD HH:MM'))+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.type === 0)?[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green"}},[_vm._v(" info ")]),_vm._v(" Info ")]:_vm._e(),(item.type === 1)?[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"orange"}},[_vm._v(" warning ")]),_vm._v(" "+_vm._s(_vm.txtMessages.txtWarning)+" ")]:_vm._e(),(item.type === 2)?[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"}},[_vm._v(" bug_report ")]),_vm._v("Error ")]:_vm._e()],2)]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.showdetailModal(item)}}},'v-icon',attrs,false),on),[_vm._v(" edit ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.txtMessages.txtDetails))])])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.listar()}}},[_vm._v(" "+_vm._s(_vm.txtMessages.txtReset)+" ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }